import { NgModule } from "@angular/core";
import { ThemeModule } from "../../@theme/theme.module";
import { CommonComponentsModule } from "../../components/common-components.module";
import { DirectiveModule } from "../../directive/directive.module";
import { SharedModule } from "../../shared/shared.module";
import { HomeRoutingModule } from "./home-routing.module";
import { HomeComponent } from "./home.component";

@NgModule({
  declarations : [
    HomeComponent
  ],
  imports : [
    HomeRoutingModule,
    ThemeModule,
    CommonComponentsModule,
    DirectiveModule,
    SharedModule,
  ]
})
export class HomeModule 
{

}