import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './DnL/components/header/header.component';

import { UserProfileComponent } from './DnL/components/user-profile/user-profile.component';
import { IonicModule } from '@ionic/angular';
import { CommonComponentsModule } from '../components/common-components.module';

const NB_MODULES = [

  IonicModule
];
const COMPONENTS = [
  HeaderComponent,

  UserProfileComponent
];
// const PIPES = [
//   CapitalizePipe,
//   PluralPipe,
//   RoundPipe,
//   TimingPipe,
//   NumberWithCommasPipe,
// ];

@NgModule({
  imports: [CommonModule, ...NB_MODULES , CommonComponentsModule],
   exports: [CommonModule,  ...COMPONENTS],//...PIPES,
   declarations: [...COMPONENTS,],//...PIPES,
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
      
      ],
    };
  }
}
