import { Component,EventEmitter,Input,OnInit,OnChanges,Output,SimpleChanges,} from "@angular/core";
import { Injectable } from "@angular/core";
import { CustomDialogComponent } from "./../../components/custom-dialog/custom-dialog.component";
import { BASE_CONFIG } from "../../util/base-settings";
import { FILE_NAME } from "../../util/constants";
import { LoggerService } from "../../services/logger/logger.service";
import {ErrorAlertVal} from '../../model/Response.model'
import { ModalController } from "@ionic/angular";
import { AlertModalData, AlertModalStatus } from "../../model/alert-modal/alert-modal.model";
@Injectable({
  providedIn: "root",
})
@Component({
  selector: "app-alert-modal",
  templateUrl: "./alert-modal.component.html",
  styleUrls: ["./alert-modal.component.css"],
})
export class AlertModalComponent implements OnInit, OnChanges {
  @Input() alertMsg: any = null;
  @Input() showModal: boolean = false;
  @Input() status: AlertModalStatus;
  @Input() description: ErrorAlertVal|string =null;
  @Input() customTitle: string = "";
  @Input() customButtonName: string = "";
  @Input() isInnerHtml: boolean = false;
  @Input() isReset: boolean = false;

  @Output() onDialogClose = new EventEmitter();
  @Output() onConfirm   = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  constructor(private modalController: ModalController,private logger:LoggerService) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.showModal && changes.showModal.currentValue) {
      this.openDialog();
    }
  }

  async openDialog() {
    try{
      if(BASE_CONFIG.IS_DEBUG) console.log("openDialog");
      let desc = [];
      if(this.description != null)
      {
        if(this.description.hasOwnProperty('codeList')){
          // desc.push('ER01')
          this.description['codeList'].filter(val => {
            desc.push(val)
          })
        }
      if(this.description.hasOwnProperty('valueList')){
        this.description['valueList'].filter(val => {
          desc.push(val)
        })
      }
      }

    // if(typeof this.description == 'string'){
    //   desc.push(this.description)
    // }

    let modalData: AlertModalData = {
      buttonName: this.customButtonName ? [this.customButtonName] : this.status==AlertModalStatus.confirm?[ $localize`:@@alertModal.noBtn:NO`, $localize`:@@alertModal.yesBtn:YES`]  : [$localize`:@@alertModal.okBtn:OK`],
      desc: desc.length>0?desc:this.description?this.description.toString():'',
      status: this.status,

      title: this.customTitle ? this.customTitle : this.status==AlertModalStatus.success?$localize`:@@alertModal-success:Success`: this.status==AlertModalStatus.confirm?$localize`:@@alertModal-confirmation:Confirmation`:$localize`:@@alertModal-failed:Failed`,
    };

    
  let modal=await this.modalController.getTop();
    var modalController = await this.modalController.create({
      component: CustomDialogComponent,
      
    backdropDismiss:false,
      componentProps: {
        data: modalData,
        emitFunc: this.getConfirm.bind(this),
        isReset:this.isReset

      },
      id:this.status+'alert-modal',
      cssClass:modal?'auto-height multi-modal': 'auto-height ' + this.status
    });
    await modalController.present();

    }
  catch(err)
  {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.ALERT_MODAL_COMPONENT,
    "error in openDialog",
    err.toString(),
     "openDialog",
      );
     this.logger.log(logRequest);

  }
}
getConfirm(pIsConfirm){

   this.onDialogClose.emit(pIsConfirm);
   this.modalController.dismiss(undefined, undefined, this.status+'alert-modal')

}

  closeDialog(): void {
    if(BASE_CONFIG.IS_DEBUG) console.log("closeDialog");
    //this.dialog.
 //   this.dialog.closeAll();
    try {
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.ALERT_MODAL_COMPONENT,
      "error in closeDialog",
      err.toString(),
       "closeDialog",
        );
       this.logger.log(logRequest);
    }
  }
}
