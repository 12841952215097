 import { BrowserModule } from "@angular/platform-browser";
 import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
 import { NgModule } from "@angular/core";
 import { HttpClientModule } from "@angular/common/http";
 import { CoreModule } from "./core/core.module";
 import { AppComponent } from "./app.component";
 import { AppRoutingModule } from "./app-routing.module";

 
 import { FormsModule, ReactiveFormsModule } from "@angular/forms";
 import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
 import { NgSelectModule } from "@ng-select/ng-select"; 
import { ThemeModule } from "./@theme/theme.module";
import { HomeModule } from "./pages/home/home.module";
import { IonicModule } from "@ionic/angular";
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { FilePondModule } from "ngx-filepond";
import {
  BarController,
  BarElement,
  Chart,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PieController,
  ArcElement,
} from 'chart.js';
import { ChartjsModule } from '@ctrl/ngx-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// What you register will depend on what chart you are using and features used.
Chart.register(BarController, BarElement, CategoryScale, LinearScale,PieController,ArcElement, Title, Tooltip, Legend,ChartDataLabels);
 @NgModule({
   declarations: [AppComponent],
   imports: [
     BrowserModule,
     ChartjsModule,
     BrowserAnimationsModule,
     HttpClientModule,
     IonicModule.forRoot({
        hardwareBackButton: true,
        //modalEnter: 
        //modalLeave: 
        // navAnimation: iosTransitionAnimation ,
        animated: true
        // rippleEffect: false,
        // mode: 'md'
      }),
     AppRoutingModule,
    
     NgxDaterangepickerMd.forRoot(),
   
     CoreModule.forRoot(),
   
     AppRoutingModule,
     ReactiveFormsModule,
     FormsModule,
     NgSelectModule,
     ThemeModule,
     HomeModule,
     FilePondModule
  
     //IonicModule.forRoot(),

   ],
   providers:[
    File,FileOpener
   ],
   bootstrap: [AppComponent],
 
 })
 export class AppModule {}
 