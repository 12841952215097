import { HttpService } from "../http/http.service";
import { BASE_CONFIG } from "../../util/base-settings";
import { FILE_NAME ,SERVICE_URL} from "../../util/constants";
import { LoggerService } from "../logger/logger.service";
import { Injectable } from "@angular/core";
import { Store } from '@ngxs/store';
import { APIResponse,Status } from "../../model/Response.model";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { TokenStorageAction } from "../../pages/auth/auth.actions";

@Injectable({
  providedIn: "root",
})
export class TokenRenewalService {
  private authToken:string
  constructor(private http: HttpService, private logger: LoggerService,
    private store:Store 
    ) {}

  renewToken(): Observable<any> {
    let retValue: Observable<any>;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("renewToken");

     
            let renewalStatus$ = this.getRenewalStatus();
            return renewalStatus$.pipe(
              map((data) => {
                this.authToken= data.status==Status.Success?data.data.authorization:''
                // if(this.authToken) {
                  this.store.dispatch(new TokenStorageAction(this.authToken));   
                //  }
                return data;
              })
            );
                 
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.TOKEN_RENEWAL_SERVICE,

        "error in renewToken",
        err.toString(),
        "renewToken"
      );
      this.logger.log(logRequest);
    }
    return retValue;
  }
 


   getRenewalStatus(): Observable<APIResponse<any>> {
    let retValue: Observable<APIResponse<any>>;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("getRenewalStatus");

      retValue = this.http.makePostRequest(SERVICE_URL.RENEW_JWT, null);
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.TOKEN_RENEWAL_SERVICE,
        "error in getRenewalStatus",
        err.toString(),
        "getRenewalStatus"
      );
      this.logger.log(logRequest);
    }
    return retValue;
  }
}
