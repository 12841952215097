import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { BackToUrlStorageAction, MenuLsStorageAction } from "./home.action";
import { CommonStoreModel } from "./home.model";

export const initialHomeState : CommonStoreModel = {
  backTo : null,
  menu:null
}

export const STATE_HOME = new StateToken<CommonStoreModel>('saas_common');

@State({
  name : STATE_HOME ,
  defaults : initialHomeState
})

@Injectable()
export class CommonState
{
  constructor()
  {

  }

  @Action(BackToUrlStorageAction)
  setMenuObj(ctx : StateContext<CommonStoreModel>,action : BackToUrlStorageAction)
  {
    ctx.patchState(
      {
        backTo : action.backTo
      }
    )
  }

  @Action(MenuLsStorageAction)
  setMenuLs(ctx : StateContext<CommonStoreModel>,action : MenuLsStorageAction)
  {
    ctx.patchState(
      {
        menu : action.menu
      }
    )
  }
}