import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { PopoverController } from "@ionic/angular";
// import { NbDialogService, NbPopoverDirective} from "@nebular/theme";
import { Store } from "@ngxs/store";
import { SignInResponse } from "sass_userbase_package";
import { LoggerService } from "../../../../../app/services/logger/logger.service";
import { ApiLogoutService } from '../../../../../app/services/login/api-logout.service';
import { BASE_CONFIG } from "../../../../../app/util/base-settings";
import { APP_ROUTES, FILE_NAME, ROLE } from "../../../../../app/util/constants";
import { ClearStoreFunction } from "../../../../core/store/clearStore";
import { selectLoginResponse } from "../../../../pages/auth/auth.selectors";
import { UtilFunctions } from "../../../../util/util";

@Component({
  selector: "ngx-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})

export class UserProfileComponent implements OnInit {

pathName: string = null;
userName: string = '';
// @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
userProfile:SignInResponse; 
  isShowProfile: boolean;
  userRole: string;
// @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  constructor(
    private logger: LoggerService,
    // private dialog: NbDialogService,
    private router: Router,
    private logOutService:ApiLogoutService,
    private store: Store ,
    private storeClear : ClearStoreFunction,
    private popoverController:PopoverController,
    private util:UtilFunctions,
  ) {
    
    // this.menuService.onItemClick().subscribe((event) => {
    //   if (event.item.data == this.logOutMenuId) {
    //     this.doLogOut();
    //   }
    // });
  }

  ngOnInit(): void {
    this.store.select(selectLoginResponse()).subscribe((loginResponse : SignInResponse) => {
      if(loginResponse)
      {
        this.userProfile = loginResponse;

        if (this.userProfile.loginDetails.userName)
        {
          this.userName = this.userProfile.loginDetails.userName.substring(0,1).toUpperCase() 
        }
        // if(this.userProfile.loginDetails.userRole[0] == ROLE.ADMIN)
        // {
        //   this.userRole = ROLE.Admin
        // }
        else
        {
          this.userName = this.userProfile.loginDetails.emailId.substring(0, 1).toUpperCase()
        }
      }
    });
  }

  doLogOut(): void {
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("doLogOut");

      // this.isShowProfile=false;
      
      this.popoverController.dismiss();
      this.logOutService.apiLogoutRes().subscribe({
        next : val => {
          this.storeClear.toClearStore();
          // this.router.navigate([APP_ROUTES.LOGIN],{replaceUrl:true});
          this.util.navigate(APP_ROUTES.LOGIN,null,null,true);
        },
        error : (error : HttpErrorResponse) => {

          this.storeClear.toClearStore();
          // this.router.navigate([APP_ROUTES.LOGIN],{replaceUrl:true});
          this.util.navigate(APP_ROUTES.LOGIN,null,null,true);
          let logRequest = this.logger.buildRequest(
            FILE_NAME.USER_PROFILE_COMPONENT,
            "error in doLogout",
            error.message.toString(),
            "doLogout"
          )
          this.logger.log(logRequest);
        }
      })
 
      // this.router.navigate([APP_ROUTES.LOGIN]);
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_PROFILE_COMPONENT,
        "error in doLogOut",
        err.toString(),
        "doLogOut"
      );
      this.logger.log(logRequest);
    }
  }
  toggleUserProfile(){
    this.isShowProfile=!this.isShowProfile;
  }
}
