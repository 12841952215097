import { SignInResponse } from "sass_userbase_package";
export class TokenStorageAction 
{
  static readonly type = '[token] set token';
  constructor(public token : string) {}

}

export class CaptchaStorageAction
{
  static readonly type = '[captchaText] set captchaText';
  constructor( public captchaText : string ) {}

}

export class LoginResponseAction
{
  static readonly type = '[LoginResponse] set loginResponse' ;
  constructor( public loginResponse : SignInResponse) {}

}
export class RememberLoginStorageAction 
{
  static readonly type = '[remember] set login';
  constructor(public remember : boolean) {}

}
export class ResetLoginStoreAction
{
  static readonly type = '[reset] project store' ;
  constructor() {}
}