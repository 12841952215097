import { PreviousUrlObject } from "./home.model";

export class BackToUrlStorageAction 
{
  static readonly type = '[backTo] set url';
  constructor(public backTo : PreviousUrlObject)
  {
    
  }
}
export class MenuLsStorageAction 
{
  static readonly type = '[menu] set store';
  constructor(public menu : any)
  {
    
  }
}