import { PlatformLocation } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
// import { NbMenuItem } from '@nebular/theme';
import { SplashScreen } from '@capacitor/splash-screen';
import { UserMenu } from 'dnl-api';
import { Observable } from 'rxjs';
import { SignInResponse } from 'sass_userbase_package';
import { DataTableService } from '../../components/data-table/data-table.service';
import { ClearStoreFunction } from '../../core/store/clearStore';
import { AlertModalStatus } from '../../model/alert-modal/alert-modal.model';
import { APIResponse, ErrorAlertVal, Status } from '../../model/Response.model';
import { HttpService } from '../../services/http/http.service';
import { LoggerService } from '../../services/logger/logger.service';
import { ApiLogoutService } from '../../services/login/api-logout.service';
import { ApiGetMenuService } from '../../services/menu/api-get-menu.service';
import { BASE_CONFIG } from '../../util/base-settings';
import { APP_ROUTES, CurrTab, DATE_TIME_FORMAT, FILE_NAME, ROLE } from '../../util/constants';
import { DateFunctions } from '../../util/date-functions';
import { UtilFunctions } from '../../util/util';
import { selectLoginResponse } from '../auth/auth.selectors';
import { MenuLsStorageAction } from './home.action';
import { selectMenuLs } from './home.selector';
import { HomeService } from './home.service';

@Component({
  selector: 'ngx-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {
  // menu: NbMenuItem[] = [];

  menu = [];
// [{
//   "children": [{
//           "icon": "layers-outline",
//           "link": "/master/projects",
//           "title": "My Projects"
//       }, {
//           "icon": "list-outline",
//           "link": "/master/projects/all",
//           "title": "All Projects"
//       }
//   ],
//   "icon": "grid-outline",
//   "link": "/master/projects",
//   "title": "Projects"
// }, {
//   "icon": "people-outline",
//   "link": "/master/coach/all",
//   "title": "Coach"
// }, {
//   "icon": "file-text-outline",
//   "link": "/master/reports",
//   "title": "Reports"
// }, {
//   "children": [{
//           "icon": "archive-outline",
//           "link": "/master/settings",
//           "title": "Report Status"
//       }, {
//           "icon": "pricetags-outline",
//           "link": "/master/pricing",
//           "title": "Pricing"
//       }, {
//           "icon": "person-done-outline",
//           "link": "/master/manage/permissions",
//           "title": "Roles & Permission"
//       }
//   ],
//   "icon": "settings-2-outline",
//   "link": "",
//   "title": "Settings"
// }
// ]
// []

  version:string = BASE_CONFIG.VERSION

  alertMsg: ErrorAlertVal|string ="";
  modalStatus: AlertModalStatus;
  isShowSplit:boolean=true;
  splitPane:any='sm';
  menuObj: any;
  isProgress: boolean=false;
  isShowMenu: boolean=true;
  showLoading:boolean=true;
  todayDate : any ;
  userName: string;
  loginResponse: any;
  currentUrl: string;
  accessRedirectUrl: any;
  constructor(
    private apiMenuService: ApiGetMenuService,
    private logger: LoggerService,
    private util:UtilFunctions,
    private router: Router,
    private logOutService:ApiLogoutService,
    private storeClear : ClearStoreFunction,
    private menuController: MenuController,
    private dataTableService: DataTableService,
    private httpService: HttpService,
    private navController :NavController,
    private platform: Platform,
    private store: Store,
    location: PlatformLocation,
    private homeService : HomeService,
    private dateFunctions: DateFunctions,

  ) {
    this.platform.backButton.subscribeWithPriority(10, () => {
      this.util.goBack();
  });
  location.onPopState(() => {
    this.util.goBack(true);
  });
   }
  //  ngAfterContentChecked() {
  //   try {
  //             if (BASE_CONFIG.IS_DEBUG) console.log("ngAfterContentChecked");
  //    } catch (err) {
  //   }
  //  }
   ngOnInit(){
      try {
        if(BASE_CONFIG.IS_DEBUG) console.log("ngOnInit");
        let extrasState=this.router.getCurrentNavigation();
        if(extrasState&&extrasState.extras&&extrasState.extras.state&&extrasState.extras.state.accessRedirectUrl){
          this.accessRedirectUrl=extrasState.extras.state.accessRedirectUrl;
       }
        this.currentUrl=this.router.url.split('/')[1];
        this.router.events.subscribe(event =>{
        if (event instanceof NavigationEnd){
          this.currentUrl=this.router.url.split('/')[1];
          this.addActiveClass(this.router.url,this.util.cloneDeep(this.menu));
        }
        });
      } catch (error) {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.HOME_COMPONENT,
          "error in ngOnInit",
          error.toString(),
          "ngOnInit"
        );
        this.logger.log(logRequest);
      }
   }

  ngAfterViewInit(): void {
    
    // this.router.events.subscribe(event =>{
    //   if (event instanceof NavigationStart){
    //   this.isProgress = true;
    //   }
    //   if (event instanceof NavigationEnd){
    //     this.isProgress = false;

    //   }
    
    // })
    this.httpService.dataSubject.subscribe(res => {
     
      this.isProgress = null
      this.isProgress = res
   
    })
    // this.onToggleSidebar(false);

    this.homeService.sideMenu.subscribe((pIsOpen)=>{
      if(pIsOpen){
       this.onToggleSidebar(true);
      }
    })
  }
  navToHome()
  {
    try {
      if(BASE_CONFIG.IS_DEBUG) console.log("navToHome");
      this.util.navigate(APP_ROUTES.DASHBOARD);
    } catch (error) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HOME_COMPONENT,
        "error in navToHome",
        error.toString(),
        "navToHome"
      );
      this.logger.log(logRequest);
    }
  }
  getTodayDate()
  { 
    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log("getTodayDate");
      this.todayDate = this.dateFunctions.formatDate( new Date() , DATE_TIME_FORMAT.COMMON3_DATE_FORMAT) ;      
    } catch (error) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HOME_COMPONENT,
        "error in getTodayDate",
        error.toString(),
        "getTodayDate"
      );
      this.logger.log(logRequest);
    }
  }
  
  ionViewWillEnter(): void {
  
   
    SplashScreen.hide();
   
   let sLoginRes= this.store.select(selectLoginResponse()).subscribe(async (loginResponse: SignInResponse) => {
      if(loginResponse)
      {
        this.loginResponse = this.util.cloneDeep(loginResponse);
        this.userName = loginResponse.loginDetails.userName.substring(0, 1).toUpperCase()

     if(loginResponse.loginDetails.userRole[0]==ROLE.EMPLOYEE){
      this.menuController.close();

       this.isShowMenu=false;

       document.body.classList.add('contractor_sec')

     }else{
      this.isShowMenu=true;

      document.body.classList.remove('contractor_sec')
      this.toGetMenuLs();
      this.onToggleSidebar(false);
      

     }
      }
    })
    sLoginRes.unsubscribe();
    // this.onNetworkChange()
    this.getTodayDate()

  }
  async splitPaneVisible(pEvent){

    if( this.menuController.isOpen('first')){
    this.menuController.close()
    }
  }
  async onToggleSidebar(pIsToggle?){
  
    const splitPane = document.querySelector('ion-split-pane');
    if(splitPane){
    const windowWidth = window.innerWidth;
    const splitPaneShownAt = 1101;
    const when = `(min-width: ${splitPaneShownAt}px)`;
    // console.error(`windowWidth ${windowWidth} \n splitPaneShownAt ${splitPaneShownAt} \n when ${when}`)
      // split pane view is visible
      const open = splitPane.when === when;
      // splitPane.when = open ? false : when;
      this.splitPane=open ? false : when;
    if (windowWidth >= splitPaneShownAt) {
      // split pane view is visible
      // const open = splitPane.when === when;
      // // splitPane.when = open ? false : when;
      // this.splitPane=open ? false : when;
    } else {

      // split pane view is not visible
      // toggle menu open
      // const menu = splitPane.querySelector('ion-menu');
      // return menu.open();
      if(pIsToggle){
        this.menuController.open();
        }
    }
    }
  }
 async onCloseSidebar(){
  try {
    if(BASE_CONFIG.IS_DEBUG)  console.log("onCloseSidebar");

    await  this.menuController.close();
  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.HOME_COMPONENT,
      "error in onCloseSidebar",
      err.toString(),
      "onCloseSidebar");
    this.logger.log(logRequest);
  }
  }
  onRightClick(event,pMenuObj){
    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log("onRightClick");
      if(!pMenuObj.isActive){
       event.preventDefault();
      }
  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.HOME_COMPONENT,
      "error in onRightClick",
      err.toString(),
      "onRightClick");
    this.logger.log(logRequest);
  }
  }
  async onClickMenu(ev,pMenuObj,isSubmenu?){
    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log("onClickMenu");
      ev.preventDefault();
      ev.stopPropagation();
    if(pMenuObj.isActive){
 
    if(!isSubmenu){
    for(let i=0;i<this.menu.length;i++){
      this.menu[i].isOpen=false;
    }
    pMenuObj.isOpen=!pMenuObj.isOpen;
  }

    this.dataTableService.setCurrTab(CurrTab.active);
    if(pMenuObj.children){
      this.addActiveClass(this.router.url,this.menu);

    }else{
   
        this.toCloseOnMenuNav();
      
   
      await this.util.navigate(pMenuObj.link,null,{allow:true});
      }
    }
  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.HOME_COMPONENT,
      "error in onClickMenu",
      err.toString(),
      "onClickMenu");
    this.logger.log(logRequest);
  }
  }
  async toCloseOnMenuNav(){
    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log("toCloseOnMenuNav");

    const splitPane = document.querySelector('ion-split-pane');
    if(splitPane){
    const windowWidth = window.innerWidth;
    const splitPaneShownAt = 1101;
    const when = `(min-width: ${splitPaneShownAt}px)`;
    if (windowWidth >= splitPaneShownAt) {
    
    } else {
    this.menuController.close();
     
    }
    }
  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.HOME_COMPONENT,
      "error in toCloseOnMenuNav",
      err.toString(),
      "toCloseOnMenuNav");
    this.logger.log(logRequest);
  }
  }
  isItsActive(pMenuObj) {

    let className=pMenuObj.isOpen?'expand':'collapse';
  let status= pMenuObj.link==this.router.url.split('?')[0]?className+' active':className+' inactive';
  // let status=  this.router.url.split('/')[1]==pMenuObj.link.split('/')[1]?className+' active':className+' inactive';
    return  (pMenuObj&&pMenuObj.children&&pMenuObj.children.length)?status +' has-children':status;
  //  if(this.menuObj.link==pMenuObj.link){

  //  }
 
   
  }
  toDeleteEmptyChildren(pSubMenu){
    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log("toDeleteEmptyChildren");

      pSubMenu.children.filter( val => {
        if(val.children.length==0){
        delete val.children;
        }
          if(val.children && val.children.length>0){
            this.toDeleteEmptyChildren(val.children)
          }
      })

    } catch (err) {
      let logRequest = this.logger.buildRequest(
    FILE_NAME.HOME_COMPONENT,
  "error in toDeleteEmptyChildren",
  err.toString(),
   "toDeleteEmptyChildren",
    );
   this.logger.log(logRequest);
    }
   return pSubMenu
  }

  toGetMenuLs():  void{
    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log("toGetMenuLs");

    let borrowerRes$:Observable<APIResponse<UserMenu[]>> = this.apiMenuService.apiGetMenuRes(null)

    borrowerRes$.subscribe({
      next : (res:APIResponse<UserMenu[]>)=> {
        if ( res.status == Status.Success)
        {
          let menuVal = [] ;
          let list = this.util.cloneDeep(res.data);
          list.filter( val => {
            val['isOpen']=false;
            // delete val.displayorder;
            if(val.children.length==0) 
            {
              delete val.children;
            }
            else
            {
              if(val.children.length>0)
              {
                val = this.toDeleteEmptyChildren(val);
              }
            }
            menuVal.push(val);
          })
          menuVal = [] ;
          menuVal = list.sort((a, b) => 
          Number(a.displayOrder) < Number(b.displayOrder) ? -1 : 1);
          for(let i=0;i<menuVal.length;i++){
            if(menuVal[i].children){
              let tempList = menuVal[i].children ;
              menuVal[i].children = [] ;
            
              menuVal[i].children=tempList.sort((a, b) =>
            Number(a.displayOrder) < Number(b.displayOrder) ? -1 : 1);
            loopChild: for(let childIndex=0;childIndex<menuVal[i].children.length;childIndex++){
              if(menuVal[i].children[childIndex].isActive){
                if(menuVal[i].children[childIndex].link==this.accessRedirectUrl&&this.accessRedirectUrl==this.currentUrl){
                  menuVal[i].isOpen=true;
                }
               menuVal[i].isActive=true;
               break loopChild;
              }
           }
            }
          }
      
          this.menu = this.util.cloneDeep(menuVal);
          this.store.dispatch(new MenuLsStorageAction( this.util.cloneDeep(menuVal)));
          this.showLoading=false;
          this.addActiveClass(this.router.url,this.menu);

        }
        else if (res.status == Status.Error)
        {
          this.alertMsg = this.util.getErrorAlertValue(res,true,false)
          this.modalStatus=AlertModalStatus.failed;
          let sMenu= this.store.select(selectMenuLs()).subscribe(async (menuLs: any) => {
          this.menu=menuLs;
          this.showLoading=false;
          });
          sMenu.unsubscribe();

        }
        else
        {
          this.alertMsg  = $localize`:@@common.tryagain:Please try again later`;
        }
      },
      error : (error : HttpErrorResponse) => {
          this.util.handleErrorStatus(FILE_NAME.HOME_COMPONENT,"toGetMenuLs",error.message.toString());
      }
    }
    );

    } catch (err) {
      let logRequest = this.logger.buildRequest(
    FILE_NAME.HOME_COMPONENT,
  "error in toGetMenuLs",
  err.toString(),
   "toGetMenuLs",
    );
   this.logger.log(logRequest);
    }
  }
  doLogout(pIsCloseSidemenu?) : void
  {
    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log("doLogout");

      this.logOutService.apiLogoutRes().subscribe({
        next : async val => {
          if(pIsCloseSidemenu){
          await this.onCloseSidebar();
          }
          this.storeClear.toClearStore();
          this.util.navigate(APP_ROUTES.LOGIN,null,null,true);
        },
        error : (error : HttpErrorResponse) => {
          this.storeClear.toClearStore();
          this.util.navigate(APP_ROUTES.LOGIN,null,null,true);
          let logRequest = this.logger.buildRequest(
            FILE_NAME.HOME_COMPONENT,
            "error in doLogout",
            error.message.toString(),
            "doLogout"
          )
          this.logger.log(logRequest);
        }
      })
      
    } catch (error) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HOME_COMPONENT,
        "error in doLogout",
        error.toString(),
        "doLogout"
      );
      this.logger.log(logRequest);
    }
  }

   addActiveClass(pCurrentUrl,pMenuList?){
    try {
      if(BASE_CONFIG.IS_DEBUG) console.log("addActiveClass");

    for(let i=0;i<pMenuList.length;i++){

        
    let className=pMenuList[i].isOpen?'expand':'collapse';
    let parentPathFromCurrentRoute=pCurrentUrl.split('/')[1];
    let menuLink=pMenuList[i].link.split('/')[1];
    let isItsActive=parentPathFromCurrentRoute.split('?')[0]==menuLink;
    let statusClass=  isItsActive?className+' active':className+' inactive';
     
     statusClass=!pMenuList[i].isActive?statusClass+' disable':statusClass;
     
     pMenuList[i].class =  (pMenuList[i]&&pMenuList[i].children&&pMenuList[i].children.length)?statusClass +' has-children':statusClass;

    if(pMenuList[i].children){
    for(let j=0;j<pMenuList[i].children.length;j++)
    {
      let status= pMenuList[i].children[j].link==pCurrentUrl.split('?')[0]?className+' active':className+' inactive';
      if(pMenuList[i].children[j].link==pCurrentUrl.split('?')[0]) {
        let className = pMenuList[i].class ;
        pMenuList[i].isOpen = true ;
        pMenuList[i].class = className.replace("collapse","expand",1);
      }
      status=!pMenuList[i].children[j].isActive?status+' disable':status;

      pMenuList[i].children[j].class= status;
    }
  }
      this.menu=pMenuList;
    }
  } catch (error) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.HOME_COMPONENT,
      "error in addActiveClass",
      error.toString(),
      "addActiveClass"
    );
    this.logger.log(logRequest);
  }
   }

   onHover(pMenuObj)
   {
     try
     {
       if (pMenuObj.isOpen) return;
 
       pMenuObj.class = 'expand';
       pMenuObj.isOpen = true;
     }
     catch (error)
     {
       let logRequest = this.logger.buildRequest(
         FILE_NAME.HOME_COMPONENT,
         "error in onHover",
         error.toString(),
         "onHover"
       );
       this.logger.log(logRequest);
     }
   }
}
