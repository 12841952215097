export class FILE_NAME {

  //service files
  static readonly HTTP_SERVICE = "http.service.ts";
  static readonly ADMIN_LOGIN_SERVICE = "api-admin-login.service.ts";
  static readonly LOGOUT_SERVICE = "api-logout.service.ts";
  static readonly TOKEN_RENEWAL_SERVICE = "token-renewal.service.ts";
  static readonly LOADER_SERVICE = "loader.service.ts";
  static readonly GET_CAPTCHA_SERVICE = "api-get-captcha-ls.service.ts";
  static readonly FORGOT_PASSWORD_SERVICE = "api-forgot-pwd.service.ts";
  static readonly GET_MENU_SERVICE = 'api-get-menu.service.ts';
  
  static readonly SUBSCRIBER_SERVICE = 'subscriber.service.ts';
  static readonly USER_SERVICE = 'user.service.ts';
  static readonly PRICING_SERVICE = 'pricing.service.ts';
  static readonly PAYMENT_SERVICE = 'payment.service.ts';

  static readonly DASHBOARD_SERVICE = 'dashboard.service.ts'


  //component files
  static readonly APP_COMPONENT = "app.component.ts";

  static readonly PERCENTAGE_DIFFERENCE_DIRECTIVE = "percentage-difference.directive.ts";
  static readonly USER_PROFILE_COMPONENT = "user-profile.component.ts"
  static readonly ALERT_MODAL_COMPONENT = "alert-modal.component.ts";
  static readonly SLIDES_COMPONENT = "slides.component.ts";
  static readonly TEXT_CONTENT_COMPONENT = "text-content.component.ts";
  static readonly TEXT_AREA_COMPONENT = "text-area.component.ts";
  static readonly C_DROPDOWN_COMPONENT = "c-dropdown-component.component.ts";
  
  static readonly CLOSABLE_ALERT_COMPONENT = "closable-alert.component.ts";
  static readonly DATA_TABLE_COMPONENT = "data-table.component.ts";
  static readonly FORM_ERRORS_COMPONENT = "form-errors.component.ts";
  
  static readonly USER_ALL_COMPONENT = "user-all.page.ts";
  static readonly USER_NEW_COMPONENT = "user-new.component.ts";

  static readonly CUSTOM_DIALOG_COMPONENT = "custom-dialog.component.ts";
  static readonly CAPTCHA_COMPONENT = "captcha.component.ts";
  static readonly HOME_COMPONENT = "home.component.ts";
  static readonly STATUS_COMPONENT = "status.component.ts"
  static readonly CUSTOM_MODAL_COMPONENT = "custom-modal.component.ts";
  static readonly REGISTER_COMPONENT = "register.component.ts";
  static readonly ICON_COMPONENT = "icon.component.ts";
  static readonly DATE_PICKER_COMPONENT = 'date-picker.component.ts'
  static readonly ACTION_POPOVER_COMPONENT = 'action-popover.component.ts';
  static readonly STATUS_POPOVER_COMPONENT = 'status-popover.component.ts';
  static readonly POPOVER_COMPONENT = 'popover.component.ts';
  static readonly PAYMENT_REPORT_COMPONENT = "payment-report.component.ts";
  static readonly CREATE_PRICING_COMPONENT = 'create-pricing.component.ts';
  static readonly CHANGE_PASSWORD_COMPONENT = 'change-password.component.ts';
  static readonly SEARCH_COMPONENT = 'search.component.ts';
  static readonly RESET_PASSWORD_COMPONENT = 'reset-password.component.ts';
  
  static readonly COLOR_PICKER_COMPONENT = "color-picker.component.ts";

  static readonly PAGINATION_LENGTH_COMPONENT = "pagination-length.component.ts";

  static readonly PROJECT_COLUMN_COMPONENT = "project-column.component.ts";

  static readonly DASHBOARD_COMPONENT = 'saas_dashboard.page.ts';


  //utils
  static readonly UTIL = "util.ts";
  static readonly TABLE_SORTING = "table-sorting.ts";
  static readonly DATE_FUNCTIONS = 'date-functions.ts';
  static readonly PROJECT_NEW = 'project-new.component.ts';
  static readonly TABLE_VALUE_ACTION= "table-value-action.component.ts";
  static readonly TABLE_INPUT_FIELD= "table-input-field.component.ts";
  static readonly HEADER_COMPONENT= "header.component.ts";
  static readonly INVOICE_REPORTS_COMPONENT = 'invoice-reports.component.ts';
  static readonly MY_ACCOUNT = 'my-account.component.ts';
  static readonly STORE_CLEAR = 'clearStore.ts';
  static readonly CLIENT_STATEMENT_COMPONENT = 'client-statement.component.ts';


  //guards
  static readonly AUTH_GUARD = "auth.guard.ts";

  static readonly APP_FILTER_MODAL = 'app-filter-modal.components.ts'
  static readonly CALENDAR_GRID = "calendar-grid.component.ts";
  static readonly INPUT_COUNTER_COMPONENT = 'input-counter.component.ts';

  static readonly AMOUNT_SUFFIX_PIPE = "amount-suffix.pipe.ts";


  static readonly LOGIN_PAGE = "login.component.ts";
  static readonly C_TEXT_COMPONENT = "c-text.component.ts";
  static readonly RESET_PASSWORD_SERVICE = "reset-pwd.service.ts";
  static readonly SUBSCRIBER_LIST = "subscriber-list.page.ts";
  static readonly PAYMENT_REPORT_LIST = "payment-report-list.page.ts";
  static readonly USER_LIST_PAGE = "user-list.page.ts";
  static readonly PRICING_LIST_PAGE = "plan-pricing.page.ts";
  static readonly ADD_USER_PAGE = "add-user.page.ts";
  static readonly ADD_PLAN_PAGE = "add-plan.page.ts";
  static readonly MANAGE_ADD_ON_PAGE = "manage-add-on.page.ts";
  
  static readonly CHECKBOX_COMPONENT = "add-plan.page.ts";


}

export class SERVICE_URL {

  //AUTH API
  static readonly SIGNIN = "/api/auth/signin";
  static readonly GET_MENU = '/api/utils/getMenus';
  static readonly LOGOUT = '/api/auth/logout';
  static readonly RENEW_JWT = "/api/auth/renew";
  static readonly FORGOT_PASSWORD = "/api/userManagement/forgotPassword";
  static readonly RESET_PASSWORD = '/api/userManagement/resetPassword';
  static readonly GET_CAPTCHA = "/api/utils/getCaptcha";
  static readonly GET_COUNT = "/api/counts";

  static readonly GET_USER_ACCESS_LIST = "/api/module/permission/roles/user";
  static readonly GET_ALL_PAYMENT_REPORT = "/api/saas/admin/payment/report";
  static readonly GET_ALL_SUBSCRIBERS = "/api/saas/admin/subscribers/get/all";
  static readonly GET_ALL_PLAN = "/api/saas/admin/plan/get/all";
  static readonly CREATE_PLAN = "/api/saas/admin/plan/create";
  static readonly UPDATE_PLAN = "/api/saas/admin/plan/update";
  static readonly GET_ALL_USER = "/api/saas/admin/user/get/all";
  static readonly DELETE_USER = "/api/saas/admin/user/delete";
  static readonly UPDATE_USER = "/api/saas/admin/user/update";
  static readonly CREATE_USER = "/api/saas/admin/user/create";
  static readonly GET_ADD_PLAN_ADDON_LIST = "/api/saas/admin/plan/module/get/all";

  static readonly GET_DASHBOARD = "/api/dashboard/getDashboard";
  static readonly GET_TOP5_SUBSCRIBERS = "/api/saas/admin/dashboard/subscribers/top";
  static readonly GET_TOP10_PRODUCTS = "/api/dashboard/getTop10Product";
  static readonly GET_TOP10_COMPANY = "/api/dashboard/company/top";
  static readonly GET_BUSINESS_OVERVIEW = "/api/dashboard/getBusinessOverview";
  static readonly GET_USER_COUNTRY = "/api/saas/admin/dashboard/user/country";
  static readonly GET_REVENUE_BY_DAY = "/api/saas/admin/dashboard/revenue/day";
  static readonly GET_APPOINTMENT_BY_DATE = "/api/dashboard/getAppointmentByDate";
  static readonly GET_COUNT_BY_USER_PLAN = "/api/saas/admin/dashboard/user/plan";
  static readonly GET_REVENUE_BY_DATE = "/api/saas/admin/dashboard/revenue/date";
  static readonly GET_PAYMENT_HISTORY = "/api/saas/admin/dashboard/payment/history";
  static readonly GET_REVENUE_DOWNLOAD = "/api/saas/admin/dashboard/revenue/download";
  static readonly GET_SUBSCRIBERS_DOWNLOAD = "/api/saas/admin/dashboard/subscribers/download";
  static readonly GET_REVENUE_SEND= "/api/saas/admin/dashboard/revenue/send";
  static readonly GET_SUBSCRIBERS_SEND = "/api/saas/admin/dashboard/subscribers/send";
  static readonly GET_NOTIFICATION = "/api/user/getNotification";
  static readonly CLEAR_NOTIFICATION = "/api/user/clearAppNotification";
  static readonly SEND_PAYMENT_REQUEST = "/api/saas/admin/subscribers/payment/req/send";

}
export enum PAY_HISTORY_TYPE {
  HISTORY = 1,
  UPCOMING =2,

}
export class ROUTER_PARAM {
  static readonly REG_MODE = "mode";
  static readonly USER_TOKEN = "token";
  static readonly LOGIN_BY = "by";
  static readonly AUTH_CODE = "code";
  static readonly STATE = "state";
  static readonly SESSION = "s";
  static readonly APPOINTMENT_ID = "id";
  static readonly RESCHEDULE = "reschedule";
  static readonly INVESTMENT = "invest";
  static readonly PARAM = "param";
  static readonly INVENTORY_LIST = 'Inventory-list';
  static readonly QUOTATION_LIST = 'quotation-list';
  static readonly INVOICE_LIST = 'invoice-list';
  static readonly PROJECT_QUOTATION_LIST = 'project-quotation-list';
  static readonly PRODUCT_LIST = 'Product-list';
  static readonly PROJECT_LIST = 'Project-list';
  static readonly DASHBOARD = "Dashboard";
  static readonly COMPANY = "Company";
  static readonly UPDATE_FLOW = "Update";
  static readonly ADD_FLOW = "Add";
  static readonly VIEW_FLOW = "View";
  static readonly MODE_PARAM = "&mode=" ;
  static readonly ONLY_MODE_PARAM ="?mode=";
  static readonly FROM_PARAM = "?from=";
  static readonly PURCHASE_LIST = 'purchase-list';
}
export class SESSION_STORAGE_KEYS {
  static readonly REG_FLOW = "reg-flow";
  static readonly NEW_SESSION = "is_new_sess";

}

export class APP_ROUTES {
  static readonly HOME = "/";
  static readonly NETWORK = "network";
  static readonly REGISTER = "register";
  static readonly DASHBOARD = "dashboard";
  static readonly AUTH = 'auth';
  static readonly USER_LIST = "list";
  static readonly USER = 'users';
  

  static readonly PAYMENT_REPORT = 'payment-report';
  static readonly SUBSCRIBERS_LIST = 'subscribers';
  static readonly PRICING = 'settings/pricing';


  //auth
  static readonly LOGIN = "auth/login";


}


export class DATE_TIME_FORMAT {
  static API_DATE_FORMAT = "yyyy-MM-dd";
  static API_TIME_FORMAT = "hh:mm:ss";
  static API_24HRS_TIME_FORMAT = "HH:mm:ss";
  static API_12HRS_TIME_FORMAT = "hh:mm";
  static AA = "aa";
  static NOTIFICATION_TIME_FORMAT = "MMM d, y, h:mm a";

  static TIMEPICKER_DISPLAY_FORMAT = "hh:mm aa";
  static TABLE_DATE_FORMAT = "dd-MM-yyyy";
  static TABLE_DATE_MONTH_FORMAT = "dd-MMM-yyyy";
  static DNL_DATE_FORMAT = "MMMM dd, yyyy";
  static COMMON_DATE_FORMAT="DD MMM YYYY";  /// commonly used date format
  static COMMON2_DATE_FORMAT = "dd MMM YYYY";
  static COMMON_DATE_TIME_FORMAT = "dd-MMM-YYYY hh:mm";
  static INVOICE_DATE_TIME_FORMAT = "dd-MMM-yyyy hh:mm";
  static COMMON3_DATE_TIME_FORMAT = "dd-MMM-yyyy hh:mm aa"
  static COMMON3_DATE_FORMAT = "dd MMM yyyy";
  static COMMON4_DATE_FORMAT = "dd-MMM-yyyy";
  static DNL_TABLE_FORMAT = "dd MMM, yyyy";
  static DAY_DATE_MONTH_YEAR_FORMAT = "EEEE-dd-MM-yyyy";
  static DAY_MONTH_NAME_YEAR_FORMAT = "DD-MON-YY";
  static DATE_MONTH_YEAR_FORMAT = "dd MMM yyyy";
  static DP_DATE_MONTH_YEAR_FORMAT = "DD MMM YYYY"; // date picker format
}

export class ROLES_PERMISSIONS {
  static DELETE = 'D';
  static APPROVE = 'A';
  static READ = 'R';
  static WRITE = 'W'

}
export class ACTIONS {
  static DELETE = 'Delete';
  static EDIT = 'Edit';
  static RESTORE = 'Restore';
  static DOWNLOAD = 'Download';
  static MANAGE = 'Manage';
  static REMINDER = 'Send Reminder';
  static ADD = "Add";
  static VIEW_PAYMENT ="View Payment";
  static PAYMENT = "Payment" ;
  static DETAILS = "Details" ;
  static PAGE_BREAK = "Page Break";
  static GENERATE_INVOICE = "Generate Invoice";
  static PRINT = 'Print';
  static ARCHIVE = "Archive";
  static VOID = "Void";
  static UPDATE = "Update"
  static UPDATE_QTY = "Update Qty"
  static COMPANY ="Company";
  static VIEW ="View";
  static SEND_QUOTATION ="Send Quotation";
  static DROPDOWN ="Dropdown";
  static SEND_INVOICE ="Send Invoice";
  static ADD_PRODUCT ="Add Product";
  static SUBMIT ="Submit";
  static SHARE = 'Share';
  static MARK_READ ='Mark Read';
  static DOWNLOAD_QUOTATION = "Download Quotation";
  static DOWNLOAD_INVOICE = "Download Invoice";
  static RESEND_INVITE = "RESEND INVITE";
  static DOWNLOAD_VOUCHER = "Download Voucher";

}





export enum STATUS{
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}


export class PLATFORM {
  static android = "ANDROID";
  static ios = "IOS";
  static web = 'WEB';
}

export enum CurrTab{
  active = 'Active',
  inactive = 'Inactive',
  deleted = 'Deleted',
  pending ="Pending" ,
  completed = "Completed" ,
  suspended = "Suspended"
}

export enum SortOrder {
  ascending = 1,
  descending = -1,
  asc = 'asc',
  desc = 'desc'
}

export enum SelectPage {
  Page1 = 1,
  Page2 = 2,
  Page3 = 3,
  Page4 = 4,
  Page5 = 5,
  Page6 = 6,
}

export enum ToastTypes{
  Success = "success",
  Warning = "warning",
  Failure = "danger"
}

export enum ButtonTypes{
  Normal = "normal",
  Confirm = "confirm"
}




export enum OrderByType {
  ascending = 1,
  descending = -1
}

export enum Symbols {
  dollar = "$",
  percentage = "%"
}
export class STYLE_CONFIG {
  static readonly M_BREAKPOINTS=[0, 0.2, 0.5, 1];
  static readonly M_INITIALBREAKPOINT= 0.2;
}
export enum ROLE {
  SYSTEM_ADMIN='System.Admin',
  EMPLOYEE ='Employee',
  ADMIN='Admin',
  Admin = 'Admin'
}
export enum ID {
 JWT_MODAL='jwt-modal'
}


export enum StatusFilter {
  All = "All"
}

export enum TableEmptyValue{
  emptyValue = '-'
}


export class PAGE_NAMES {
  static readonly SUBSCRIBER_LIST = "Subscriber List";
  static readonly PAYMENT_REPORT_LIST = "Payment-Report List";
  static readonly USER_LIST = "user List";
  static readonly PRICING_LIST = "Pricing List";
}
export class LOCAL_STORAGE_KEYS{
  static readonly FORCE_CLEAR_VERSION = "forceClearVersion";
}

export enum FieldOption {
  Input = 'input',
  Dropdown ='dropdown',
  Popover  ='popover', 
}

export enum EnumAccessList {
  Accounting= 'Accounting',
  Balance_sheet='Balance_sheet',
  Client_Statement='Client_Statement',
  Clients='Clients',
  Company='Company',
  Dashboard_Main='Dashboard_Main',
  Delivery_order='Delivery_order',
  Employee='Employee',
  Inventory= 'Inventory',
  Invoice='Invoice',
  Invoice_reports='Invoice_reports',
  Overview='Overview',
  Payment_Method= 'Payment_Method',
  Payment_Terms='Payment_Terms',
  Product='Product',
  Product_category='Product_category',
  Profit_loss='Profit_loss',
  Project='Project',
  Project_Appointment='Project_Appointment',
  Purchase_Order='Purchase_Order',
  Quotation='Quotation',
  Report='Report',
  Settings='Settings',
  Supplier='Supplier',
  System_Setting='System_Setting',
  Users='Users',
  Warehouse='Warehouse',
  Installation='Installation',
  Measurement='Measurement',
  My_Dashboard= 'My_Dashboard',
  Prefix= 'Prefix',
  ClientCompany='ClientCompany',
  Expense ='Expense'
  
  }


  export enum InvoiceStatus {
    Paid = 1 ,
    Pending = 2 ,
    Failed = 3 
  }

  export enum Unlimited {
    Project_Limit = 11 ,
    Admin_User_Limit = 101 ,
    Unlimited_Text = 'Unlimited'
  }

  export enum PlanFrequencyType {
    Monthly = 1 ,
    Yearly ,
    Daily ,
    Weekly
  }

  export class ALERT_MODAL {
    static readonly FORGOT_PWD_TITLE = 'Check Your Inbox';
  }

  export enum MAIN_COUNTRIES {
    Singapore = 'Singapore',
    Malaysia = 'Malaysia',
    Indonesia = 'Indonesia',
    Vietnam = 'Vietnam',
    Thailand = 'Thailand',
    Philippines = 'Philippines',
    India = 'India',
    China = 'China',
    Sri_Lanka = 'Sri Lanka',
    Hong_Kong = 'Hong Kong SAR China',
    Bangladesh = 'Bangladesh',
  }