import { Injectable } from "@angular/core";
import {
  CanActivate,
  UrlTree,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import {  map } from "rxjs/operators";
import { SignInResponse } from "sass_userbase_package";

import { LoggerService } from "../../services/logger/logger.service";

import {
  APP_ROUTES,
  FILE_NAME,
} from "../../util/constants";
import { selectLoginResponse } from "../auth/auth.selectors";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  previousUrl: string = null;
  currentUrl: string = null;
  nextState: RouterStateSnapshot;
  constructor(
    private router: Router,
    private logger: LoggerService,
    private store: Store,
  
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    try {
     
      return this.store.select(selectLoginResponse()).pipe(
        map((res: SignInResponse) => {
          if (res && res.loginDetails) {
            return true;
          } else {
            this.router.navigate([APP_ROUTES.LOGIN], { replaceUrl: true });
            return false;
          }
         
        })
      );

     
    } catch (error) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_GUARD,
        "error in canActivate",
        error.toString(),
        "canActivate"
      );
      this.logger.log(logRequest);
    }
  }

 
}
