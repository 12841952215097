import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, NgxsModuleOptions } from '@ngxs/store';
import { environment } from '../../../environments/environment';
import { DataTableState } from '../../components/data-table/data-table.state';
import { AuthState } from '../../pages/auth/auth.state';
import { CommonState } from '../../pages/home/home.state';
import { PlanState } from '../../pages/home/settings/plan-pricing/plan.state';
import { UserState } from '../../pages/home/users/user.state';


const ngxsMainStates = 
[
  AuthState ,
  DataTableState,
  CommonState,
  UserState,
  PlanState
];
const ngxsStorageStates = 
[
  AuthState,
  DataTableState,
  CommonState,
  UserState,
  PlanState
];

const ngxsConfig: NgxsModuleOptions = {
  developmentMode: !environment.production,
  selectorOptions: {
    suppressErrors: false,
    injectContainerState: false,
  },
  compatibility: {
    strictContentSecurityPolicy: true,
  },
};

export const ngxsImports = [
  NgxsModule.forRoot(ngxsMainStates, ngxsConfig),
  NgxsStoragePluginModule.forRoot({
    key: ngxsStorageStates,
  }),
  NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
  NgxsFormPluginModule.forRoot(),
  NgxsRouterPluginModule.forRoot(),
];
