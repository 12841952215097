import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  // const head = document.getElementsByTagName('head')[0];
  // const link = document.createElement('link');
  // link.type = 'text/scss';
  // link.rel = 'stylesheet';

  // link.href='assets/app.scss';
  // head.appendChild(link);

