import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CurrTab } from '../../util/constants';

@Injectable({
  providedIn: 'root'
})
export class DataTableService
{
  currTab = new BehaviorSubject(CurrTab.active);
  isAddFormSubmitted = new BehaviorSubject(false);
  isQuotationFormSubmitted = new BehaviorSubject(false);
  isPurchaseFormSubmitted = new BehaviorSubject(false);
  priceFieldValue = new BehaviorSubject([{}, {}]);
  pageNoFieldValue = new BehaviorSubject({});
  productFieldValue = new BehaviorSubject({});
  productDescValue = new BehaviorSubject({});
  pageBreakValue = new BehaviorSubject({});
  appointmentPICvalue = new BehaviorSubject({});
  addErrors = {};
  rowErrors: any = {};

  constructor() { }

  setCurrTab(pCurrTab: any)
  {
    this.currTab.next(pCurrTab);
  }

  setAppointmentPICvalue(value)
  {
    this.appointmentPICvalue.next(value);
  }

  setAddFormSubmitted(pIsSubmitted: boolean)
  {
    this.isAddFormSubmitted.next(pIsSubmitted);
  }

  setQuotationFormSubmitted(pIsSubmitted: boolean)
  {
    this.isQuotationFormSubmitted.next(pIsSubmitted);
  }

  setAddErrors(controls)
  {
    this.addErrors[Object.keys(controls)[0]] = controls[Object.keys(controls)[0]];
  }

  setRowErrors(pKey, controls)
  {
    if (!this.rowErrors[pKey])
    {
      this.rowErrors[pKey] = {};
    }
    this.rowErrors[pKey][Object.keys(controls)[0]] = controls[Object.keys(controls)[0]];
  }

  clearAddErrs()
  {
    this.addErrors = {};
  }
  clearRowErrs()
  {
    this.rowErrors = {};
  }
  setPrice(pKey, pValue)
  {
    let priceFieldValue = this.priceFieldValue.value;
    priceFieldValue[0][pKey] = pValue;
    this.priceFieldValue.next(priceFieldValue);
  }

  setQty(pKey, pValue)
  {
    let priceFieldValue = this.priceFieldValue.value;
    priceFieldValue[1][pKey] = pValue;
    this.priceFieldValue.next(priceFieldValue);
  }

  clearPriceErrs()
  {
    this.priceFieldValue.next(null);
  }

  setProductName(pKey, pValue)
  {
    let productCodeValue = this.productFieldValue.value;
    if(!productCodeValue){
      productCodeValue={};
    }
    productCodeValue[pKey] = pValue;
    this.productFieldValue.next(productCodeValue);
  }

  setProductDesc(pKey, pValue)
  {
    let productDescValue = this.productDescValue.value;
    if(!productDescValue){
      productDescValue={};
    }
    productDescValue[pKey] = pValue;
    this.productDescValue.next(productDescValue);
  }

  setPageBreak(pKey, pValue)
  {
    let pageBreakValue = this.pageBreakValue.value;
    pageBreakValue[pKey] = pValue;
    this.pageBreakValue.next(pageBreakValue);
  }

  clearProductFieldVal()
  {
    this.productFieldValue.next(null);
  }

  setPurchaseFormSubmitted(pIsSubmitted: boolean)
  {
    this.isPurchaseFormSubmitted.next(pIsSubmitted);
  }

  setPageNo(pKey, pValue)
  {
    let pageNoFieldValue = this.pageNoFieldValue.value;
    pageNoFieldValue[pKey] = pValue;
    this.pageNoFieldValue.next(pageNoFieldValue);
  }
}


