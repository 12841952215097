
<div *ngIf="alertMsg && !showModal" show  class="agree-label">
  <div *ngIf="alertMsg&&alertMsg.hasOwnProperty('valueList')"
    >
<p *ngFor="let value of alertMsg.valueList" >
      {{value}}
    </p>
    </div>
  <div *ngIf="alertMsg && alertMsg.hasOwnProperty('codeList')" class="show_error_code">
    <span i18n="@@alertmodal-errorcode">Error Code </span>:
    <span *ngFor="let code of alertMsg.codeList" >
      {{ code + " " }}
    </span>
  </div>
  <div *ngIf="alertMsg && (!alertMsg.hasOwnProperty('codeList') ||
  !alertMsg.hasOwnProperty('valueList'))">
    <p>
      {{ alertMsg }}
    </p>
  </div>
</div>
