import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreventdoubleclickDirective } from './preventdoubleclick.directive';
import { MenuAnimationDirective, PageAnimationDirective } from './page-animation.directive';
import { PercentageDifferenceDirective } from './percentage-difference.directive';




@NgModule({
  declarations: [
    PreventdoubleclickDirective,
    PageAnimationDirective,
    MenuAnimationDirective,
    PercentageDifferenceDirective
  ],
  imports: [
    CommonModule
  ],
  exports:[PreventdoubleclickDirective, PageAnimationDirective,MenuAnimationDirective, PercentageDifferenceDirective]
})
export class DirectiveModule { }
