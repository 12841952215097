import { OrderPaymentStatus } from "../report/payment-report.model";

export interface GetProjectRequest {
  isAllProject: boolean;
  userId: string;
}

export interface Project {
  id: string;
  projectName: string;
  startDate: string;
  status: number;
  createdBy: string;
  unusedTestCodes: string;
  usedTestCodes: string;
  totalTestCodes: string;
  firstName: string;
  lastName: string;
}

export interface ProjectLs {
  projects : Array<Project>
}
export interface ProjectFromFlow {
  from : string
}
export interface ProjectName {
  name : string
}
export interface GetProjectResponse {
  projects: Array<Project>
}
export interface createProjectRequest {


  "projectName": string,
  "startDate": string,
  "planId": number,
  "sendResultTo": number
  "codeCount": number
  "isSentCoachReport": boolean,
  "userId":string
}

export interface createProjectResponse {
  data:ProjectCreateInfo,
  status: string
}
export interface ProjectCreateInfo {
  isSaved: boolean,
  buyCode: BuyCode
}
export interface BuyCode {
  orderId: string,
  orderPaymentId: string,
  paymentProviderId: string
}
export interface PurchaseTestCodesReq {
  "planId": number,
  "sendResultTo": number,
  "projectId": number,
  "isSentCoachReport":boolean,
  "codeCount":number
}
export interface PurchaseTestCodesAdminReq {

  "sendResultTo": number,
  "projectId": number,
  "isSentCoachReport":boolean,
  "codeCount":number

}
export interface PurchaseTestCodesRes
{
  data:PurchaseCodeInfo,
  status: string
}
export interface PurchaseCodeInfo {
  buyCode: BuyCode
}
export enum PAYMENT_QUERY {
  PROVIDER_ID = "provider_id",
  ORDER_PAYMENT_ID = "order_payment_id",
  SESSION_ID = "session_id",
  REDIRECT_URL = "redirect_url",
  AUTH_TOKEN = "auth_token",
  ORG_ID = "org_id",
  TOKEN = "token",
  STATUS ="status",
  ERROR_CODE="error_code",
  ERROR_MESSAGE="error_mgs"
}
export enum PROCEED_TO {

  ERROR_PAGE = "ERROR-PAGE",
  SUCCESS_PAGE = "SUCCESS-PAGE",
  PENDING = "PENDING-PAGE",
  FAILED_PAYMENT_PAGE = "FAILED-PAYMENT-SELECT-PAGE"
}
export interface PaymentErrorObj {
 status :  PaymentErrorStatus,
 errorCode : string,
 errorMessage : string

}
export enum PaymentErrorStatus {
  SUCCESS ="0",
  ERROR ="1"

 }
export interface TestCode {

  assignedAt: string
  isSentCoachReport: boolean
  sendResultTo: number
  status: number
  testId: string
  testTakerEmail: string
  testTakerName: string

}
export interface GetTestCodesResponse {

  testCodes: Array<TestCode>,
  projectName : string,
  totalTestCodes: string,
  totalUnusedTestCodes: string,
  totalUsedCodes: string
}
export interface GetTestCodesRequest {
  projectId: number
}
export interface updateProjectRequest {
  id: string;
  projectName: string;
  startDate: string;
}

export interface updateProjectResponse {

}
export enum TestCodeStatus {
  NOT_USED = 1,
  ASSIGNED = 2,
  STARTED = 3,
  COMPLETED = 4
}

export enum PopoverActionCode {
   EDIT = 1,
   COMPANY = 2,
   DOWNLOAD = 3 ,
   SEND_INVOICE = 4 ,
   PRINT = 5,
   SEND_REMAINDER = 6 ,
   ARCHIVE = 7,
   UPDATE = 8 ,
   UPDATE_QUANTITY = 9,
   DELETE = 10,
   VIEW = 11,
   VIEW_PAYMENT = 12,
   MANAGE = 12,
   RESTORE = 13,
   SEND_QUOTATION = 14,
   PAYMENT = 15,
   DETAILS = 17,
   PAGE_BREAK = 18,
   GENERATE_INVOICE = 19,
   SHARE = 20 ,
   MARK_READ = 21 ,
   GENERATE_PO = 22 ,
   SENT = 23 ,
   VOID = 24 ,
   GENERATE_DO = 25 ,
   DOWNLOAD_INVOICE = 26,
   DOWNLOAD_VOUCHER = 27,
   SEND_PAYMENT_REQUEST = 28
}
export interface ActionPopover {
  id: PopoverActionCode,
  name: string,
  disable: boolean
}

export enum BatchActionCode {
  DOWNLOAD_REPORT = 1,
  MOVE_UNUSED_CODES = 2,
  ARCHIEVE_PROJECTS = 3,
  SEND_9POV_REPORT = 4,
  DOWNLOAD_REPORT_AS_ZIP = 5,
  DOWNLOAD_RAW_DATA = 6,
  SEND_REGISTRATION_EMAIL = 7,
  DOWNLOAD_COACH_REPORT_AS_ZIP = 8,
  RESEND_INVITE = 9,
  DOWNLOAD_9POV_REPORT_AS_ZIP = 10,
}
export interface BatchAction {
  id: BatchActionCode,
  name: string,
  disable: boolean,
 
}


export interface GetOrderDetailsRequest {
  token: string
}
export interface GetOrderDetailsResponse {
  data:OrderDetail,
  status:string
}
export interface OrderDetail {
  approvalCode: string,
  orderStatus: OrderPaymentStatus,
  proceedTo: string,
  projectId: string,
  status: string,
  transactionCode: string

}


export interface ArchiveProjectReq {
  id: Array<number>,
  status: number

}

export enum ARCHIVE_STATUS {
  ACTIVE = 1,
  ARCHIVED = 2,

}

export interface ArchiveProjectRes {

}

export interface ProjectFilter
{
  searchVal: string | null,
  clientId: string | null,
  loadDeleted: boolean,
  date: {
    startDate: string | null,
    endDate: string | null,
  }
}

export interface PurchaseOrderDetailIds
{
  projectId: string,
  purchaseId: string,
  purchaseOrderDetailId: string,
  appointmentId: string,
  categoryID : string ,
  categoryName : string
}
export enum PaymentStatus {
  paid = "Paid",
  pending = "Pending",
  partialPaid = "Partial Paid"
}