import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { BASE_CONFIG } from "../../util/base-settings";
import { FILE_NAME } from "../../util/constants";
import { LoggerService } from "../logger/logger.service";
@Injectable({
  providedIn: "root",
})
export class LoaderService {
  private showLoading: Subject<boolean> = new Subject();

  constructor(private logger: LoggerService) {}

  showLoader(): void {
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("showLoader");



      this.showLoading.next(true);
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.LOADER_SERVICE,
        "error in showLoader",
        err.toString(),
        "showLoader"
      );
      this.logger.log(logRequest);
    }
  }

  hideLoader(): void {
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("hideLoader");



      this.showLoading.next(false);
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.LOADER_SERVICE,
        "error in hideLoader",
        err.toString(),
        "hideLoader"
      );
      this.logger.log(logRequest);
    }
  }

  isLoading(): Observable<boolean> {
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("isLoading");

      return this.showLoading.asObservable();
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.LOADER_SERVICE,
        "error in isLoading",
        err.toString(),
        "isLoading"
      );
      this.logger.log(logRequest);
    }
  }
}
