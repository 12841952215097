import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../guard/auth.guard";
import { HomeComponent } from "./home.component";

const routes : Routes = [
  { 
    path : '', 
    component : HomeComponent , 
    canActivate : [AuthGuard],
    children : [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: "full",
      },
      {
        path: 'subscribers',
        children : [
          {
            path : '',
            loadChildren: () => import('./subscribers/subscribers.module').then( m => m.SubscribersPageModule)
          }
        ]
      },
      {
        path: 'dashboard',
        children : [
          {
            path : '',
            loadChildren: () => import('./sass_dashboard/sass_dashboard.module').then( m => m.DashboardPageModule)
          }
        ]
      },
      {
        path: 'payment-report',
        children : [
          {
            path : '',
            loadChildren: () => import('./payment-report/payment-report.module').then( m => m.PaymentReportPageModule)
          }
        ]
      },
      {
        path: 'users',
        children : [
          {
            path : '',
            loadChildren: () => import('./users/users.module').then( m => m.UsersPageModule)
          }
        ]
      },
      {
        path: 'settings',
        children : [
          {
            path : '',
            loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
          }
        ]
      },
      
      {
        path:'**',
        redirectTo: '' 
      }
      
    ]},



]

@NgModule({
  imports : [ RouterModule.forChild(routes)],
  exports : [ RouterModule ]
})

export class HomeRoutingModule 
{

}