<div>
  <ion-toolbar class="page_toolbar" *ngIf="!hideHeader">
    <ion-buttons slot="start" class="header_menu">
      <ion-button (click)="toggleSidebar()" autoHide="false" *ngIf="isShowMenu">
        <img src="/assets/svg/hamburger.svg" class="toggle_img web_menu" />
      </ion-button>
    </ion-buttons>
    <div class="page_title">
      <ion-title>
        {{ title }}
        <p>{{ description }}</p>
      </ion-title>
    </div>
    <ion-buttons slot="end" class="back_arrow">
      <ion-button (click)="onClickBack()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
      <ngx-user-profile> </ngx-user-profile>
    </ion-buttons>
  </ion-toolbar>
</div>

<app-alert-modal
  [status]="modalStatus"
  [description]="alertMsg"
  [showModal]="showAlertModal"
  (onDialogClose)="onAlertModalClose($event)"
>
</app-alert-modal>
