import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { CaptchaStorageAction, LoginResponseAction, ResetLoginStoreAction, TokenStorageAction } from "../../pages/auth/auth.actions";
import { LoggerService } from "../../services/logger/logger.service";
import { BASE_CONFIG } from "../../util/base-settings";
import { FILE_NAME, LOCAL_STORAGE_KEYS } from "../../util/constants";

@Injectable({
  providedIn: "root"
})

export class ClearStoreFunction
{
  constructor(private store: Store, private logger: LoggerService, )
  {

  }

  toClearStore()
  {
    try
    {
      this.store.dispatch(new LoginResponseAction(null));
      this.store.dispatch(new TokenStorageAction(null));
      this.store.dispatch(new CaptchaStorageAction(null));
      this.store.dispatch(new LoginResponseAction(null));

    } catch (error) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.STORE_CLEAR,
        "error in clearStoreFunction",
        error.toString(),
        "toClearStore"
      );
      this.logger.log(logRequest);
    }
  }

  forceUpdateStore()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("forceUpdateStore");
  
      // this.store.dispatch(new ResetAuthStoreAction())
      this.store.dispatch(new ResetLoginStoreAction())
     

      localStorage.setItem(LOCAL_STORAGE_KEYS.FORCE_CLEAR_VERSION, BASE_CONFIG.VERSION);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in forceUpdateStore",
        err.toString(),
        "forceUpdateStore",
      );
      this.logger.log(logRequest)
    }
  }
  forceClearLocalStorage() {
    try {
        if (BASE_CONFIG.IS_DEBUG) console.log("forceClearLocalStorage");
       let forceClearVersion= localStorage.getItem(LOCAL_STORAGE_KEYS.FORCE_CLEAR_VERSION);
       
        if(BASE_CONFIG.isForceClearLocalStorage&&forceClearVersion!=BASE_CONFIG.VERSION){
          this.forceUpdateStore();
        }
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
      "error in forceClearLocalStorage",
      err.toString(),
       "forceClearLocalStorage",
        );
       this.logger.log(logRequest);
    }
}
}