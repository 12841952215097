import { Injectable } from "@angular/core";
import {
  LOG_TYPE,
  LoggerRequest,
} from "../../model/logger/logger.model";
import {
  ActivatedRoute,
  Params,
  Router,
  NavigationExtras,
} from "@angular/router";

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BASE_CONFIG } from "../../util/base-settings";
import { FILE_NAME } from "../../util/constants";

@Injectable({
  providedIn: "root",
})
export class LoggerService {
  constructor(private currentRoute: Router,
    private http:HttpClient
    ) {}

  buildRequest(
    pModuleName: string,
    pErrTitle: string,
    pErrMessage: string,
    pFunctionName: string,
    pLogType: LOG_TYPE = LOG_TYPE.ERROR
  ): LoggerRequest {
    let retValue: LoggerRequest;
    try {
      retValue = {
        appId: BASE_CONFIG.LOG_APP_ID,
        browser: BASE_CONFIG.BROWSER,
        browserversion: "",
        ostype: BASE_CONFIG.PLATFORM,
        errtitle: pErrTitle,
        functionname: pFunctionName,
        level: pLogType,
        message: pErrMessage,
        module: pModuleName,
        pathName: this.currentRoute.url,
        timestamp: new Date().toISOString(),
        versioncode: BASE_CONFIG.VERSION,
      };
    } catch (err) {
      let logRequest = this.buildRequest(
        'logger',
       "error in buildRequest logger",
      err.toString(),
       "buildRequest ",
        );
       this.log(logRequest);
    }
    return retValue;
  }

  log(pLogRequest:LoggerRequest): void {
    try {
          this.http.post(BASE_CONFIG.LOGGER_URL,pLogRequest).subscribe()

    } catch (err) {

    }
  }


}
