import { environment } from '../../environments/environment';


export class BASE_CONFIG {
    static readonly IS_DEBUG=environment.production;
    static readonly VERSION=environment.version;
    static readonly LOGGER_URL = environment.loggerUrl;
    static readonly LOG_APP_ID=environment.logAppId
    static readonly MIN_NO_OF_TABLE_ROWS = 10;
    static readonly DATE_FORMAT = 'dd-mmm-yyyy';
    static readonly CURRENCY_CODE = 'USD'

    static IS_MASTER_FLOW = false;
    static PLATFORM='';
    static BROWSER='';
    static isForceClearLocalStorage=true;
    static DEVICE_ID = null;
    static DEVICE = null;
    static NETWORK_STATUS=false;
    }
