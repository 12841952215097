<div class="user_profile">
  <div
    *ngIf="userProfile && userProfile.loginDetails"
    id="alignment-button"
    class="user_profile_desc"
  >
  <div class="profile_info">
    <div class="name">{{ userProfile.loginDetails.userName }}</div>
    <div class="profile-t-tag" >
      <span>{{ userProfile.loginDetails.userRole[0] }}</span>
    </div>
  </div>
    <!-- <div>
      <div class="name">{{ userProfile.loginDetails.userName }}</div>
      <div class="profile-t-tag" *ngIf="userRole">
        <span>{{ userRole }}</span>
      </div>
    </div> -->
    <ion-avatar >
      <span>{{ userName }}</span>
    </ion-avatar>
  </div>
  <ion-popover trigger="alignment-button" class="profile_desc">
    <ng-template>
      <ion-list lines="none">
        <ion-item>
          <div class="email_id" v-if="userLogoDet.logoDet.email">
            <div v-if="userLogoDet.logoDet.email">
              <span> {{ userProfile.loginDetails.emailId }} </span>
            </div>
          </div>
        </ion-item>
        <ion-item>
          <div class="logout" (click)="doLogOut()">
            <span>Logout</span>
          </div>
        </ion-item>
      </ion-list>
    </ng-template>
  </ion-popover>
</div>
