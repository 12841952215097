import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { APP_ROUTES } from "./util/constants";

export const routes: Routes = [

  {
    path: APP_ROUTES.AUTH,
    children:[
      {
      path:'',
      loadChildren: () =>
      import("./pages/auth/auth.module").then(m => m.AuthModule),
      }
    ]
    
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: "full",
  },
  // {
  //   path: 'network',
  //   children:[
  //     {
  //     path:'',
  //   loadChildren: () => import('./pages/network/network.module').then( m => m.NetworkPageModule)
  //     }
  //   ]
  // },
  
  {
    path : '' ,
    children:[
      {
      path:'',
    loadChildren : () =>
    import('./pages/home/home.module').then(m => m.HomeModule)
      }
    ]
  }
  
];

const config: ExtraOptions = {
  // preloadingStrategy : PreloadAllModules ,
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})

export class AppRoutingModule {}