import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SmartTableModule } from 'ascent24-data-table';
import { IonicSelectableModule } from 'ionic-selectable';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DirectiveModule } from '../directive/directive.module';
import { AmountSuffixCCPipe } from '../pipes/amount-suffix-cc.pipe';
import { AmountSuffixPipe } from '../pipes/amount-suffix.pipe';
import { SharedModule } from '../shared/shared.module';
import { ActionPopoverComponent } from './action-popover/action-popover.component';
import { PopoverComponent } from './action-popover/popover/popover.component';
import { AddModalComponent } from './add-modal/add-modal.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { AppFilterModalComponent } from './app-filter-modal/app-filter-modal.component';
import { ButtonComponent } from './button/button.component';
import { CDropdownComponent } from './c-dropdown-component/c-dropdown-component.component';
import { CTextComponent } from './c-text/c-text.component';
import { CaptchaComponent } from './captcha/captcha.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { CustomDialogComponent } from "./custom-dialog/custom-dialog.component";
import { CustomModalComponent } from './custom-modal/custom-modal.component';
import { ColorViewComponent } from './data-table/color-view/color-view.component';
import { DataTableComponent } from './data-table/data-table.component';
import { TableInputFieldComponent } from './data-table/table-input-field/table-input-field.component';
import { TableValueActionComponent } from './data-table/table-value-action/table-value-action.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { FormErrorsComponent } from './form-errors/form-errors.component';
import { FormErrorsComponentModule } from './form-errors/form-errors.component.module';
import { IconComponent } from './icon/icon.component';
import { MenuListPopoverComponent } from './menu-list-popover/menu-list-popover.component';
import { PaginationLengthComponent } from './pagination-length/pagination-length.component';
import { SalesStatusComponent } from './sales-status/sales-status.component';
import { SearchComponent } from './search/search.component';
import { SkeletonTextComponent } from './skeleton-text/skeleton-text.component';
import { SlidesComponent } from './slides/slides.component';
import { StatusPopoverComponent } from './status-popover/status-popover.component';
import { StatusComponent } from './status/status.component';
import { StyleTextComponent } from './style-text/style-text.component';
import { TableColumnIconComponent } from './table-column-icon/table-column-icon.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { TextContentComponent } from './text-content/text-content.component';
import { ValueFieldComponent } from './value-field/value-field.component';
;
@NgModule({
  declarations: [
    CustomDialogComponent,
    CDropdownComponent,
    DataTableComponent,
    CaptchaComponent,
    StatusComponent,
    ActionPopoverComponent, 
    TableValueActionComponent, 
    CustomModalComponent, 
    AddModalComponent,
    DatePickerComponent,
    CustomModalComponent, 
    SearchComponent, 
    PopoverComponent,
    PopoverComponent,
    PaginationLengthComponent,
    SkeletonTextComponent,
    ValueFieldComponent,
    TableInputFieldComponent,
    AppFilterModalComponent,
    TableColumnIconComponent,
    MenuListPopoverComponent,
    SlidesComponent,
    TextAreaComponent ,
    AmountSuffixPipe , 
    ColorPickerComponent,
    ColorViewComponent,
    IconComponent,
    TextContentComponent,
    CTextComponent,
    AlertModalComponent,
    ButtonComponent,
    StyleTextComponent,
    SalesStatusComponent,
    AmountSuffixCCPipe,
    StatusPopoverComponent
  ],
  imports: [
    Ng2SmartTableModule,
    NgxDaterangepickerMd,
    NgSelectModule,
    IonicSelectableModule,
    DirectiveModule,
    SharedModule,
    IonicModule,
    FormErrorsComponentModule
  ],
  exports: [
    AppFilterModalComponent,
    DataTableComponent,
    DatePickerComponent,
    ActionPopoverComponent,
    CustomDialogComponent,
    CDropdownComponent,
    CaptchaComponent,
    StatusComponent,
    CustomModalComponent,
    SearchComponent,
    AddModalComponent,
    SkeletonTextComponent,
    ValueFieldComponent,
    TableInputFieldComponent,
    TableColumnIconComponent,
    MenuListPopoverComponent,
    SlidesComponent,
    TextAreaComponent ,
    AmountSuffixPipe ,
    ColorPickerComponent,
    ColorViewComponent,
    IconComponent,
    TextContentComponent,
    CTextComponent,
    AlertModalComponent,
    ButtonComponent,
    StyleTextComponent,
    SalesStatusComponent,
    AmountSuffixCCPipe,
    FormErrorsComponent,
    StatusPopoverComponent
  ]
})
export class CommonComponentsModule { }
