<!-- 
<ion-split-pane when="sm" contentId="main-content">
  <ion-menu contentId="main-content" menuId="first" class="dnl_sidemenu">
    <ion-header class="site_header"> -->
      <!-- appMenuAnimation -->
<ion-split-pane [when]="splitPane" contentId="main-content" >

  <ion-menu contentId="main-content" menuId="start" class="dnl_sidemenu" *ngIf="isShowMenu" [side]="'start'">
  
    

    <ion-content class="sidemenu_content custom_animation"  *ngIf="showLoading" [scrollY]="false">

      

        <ion-list lines="none" class="menus">
          <ion-header class="site_header" *ngIf="showLoading">
            <ion-toolbar translucent>
              <!-- <div class="sidemenu_logo">
                <ion-skeleton-text animated class="skeleton-text-animated" style="visibility: hidden;"></ion-skeleton-text>
              </div> -->
              <div class="sidemenu_logo" (click)="navToHome()"><img src="/assets/svg/logo-blue.svg" /> 
                <p>Version {{version}}</p></div>
            </ion-toolbar>
          </ion-header>
          <ion-item>
            <ion-skeleton-text animated class="skeleton-text-animated"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated class="skeleton-text-animated"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated class="skeleton-text-animated"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated class="skeleton-text-animated"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated class="skeleton-text-animated"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated class="skeleton-text-animated"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated class="skeleton-text-animated"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated class="skeleton-text-animated"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated class="skeleton-text-animated"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated class="skeleton-text-animated"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated class="skeleton-text-animated"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated class="skeleton-text-animated"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated class="skeleton-text-animated"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated class="skeleton-text-animated"></ion-skeleton-text>
          </ion-item>
            </ion-list>

            <ion-list class="logout" lines="none">
            <ion-item> 
              <ion-skeleton-text animated class="skeleton-text-animated" style="margin-left: 16px;"></ion-skeleton-text>
              </ion-item>
              </ion-list>
    </ion-content>

     <ion-content class="sidemenu_content" appPageAnimation  *ngIf="!showLoading" [scrollY]="false">
          <div class="header_part hide_web show_app">
          

           

            <div class="d-flex profile_section">
              <!-- (click)="navToProfile()" -->
              <div class="profile" >
                <ion-avatar>
                  <span>{{userName}}</span>
                </ion-avatar>
              </div>
            
              <div class="profile_content">
                <div i18n="@@subHome-userName" class="name-with-icon"><h2>Hi {{loginResponse.loginDetails.userName}}</h2><img src="/assets/svg/hand.svg" alt=""></div>
                <div ><h5 i18n="@@subHome-welcomeNote">Welcome to Door & Lock App</h5></div>
                <div><span>{{todayDate}}</span></div>
                <div><span>V{{version}}</span></div>
              </div>
            </div>

            <div class="menu_sub_title"> <h3>Menus</h3> </div>
        </div>

      <ion-list class="menus" lines="none">
        <ion-header class="site_header"  *ngIf="!showLoading">
          <ion-toolbar translucent>
            <div class="sidemenu_logo" (click)="navToHome()"><img src="/assets/svg/logo-blue.svg" /> 
              <p>Version {{version}}</p></div>
                <ion-buttons slot="end" class="app_close" (click)="onCloseSidebar()">
                  <ion-button>
                    <ion-icon name="close-outline"></ion-icon>
                  </ion-button>
                </ion-buttons>
                <ion-buttons slot="start"  class="app_logout" (click)="doLogout(true)">
                  <ion-button>
                    <ion-icon name="log-out-outline"></ion-icon>
                  </ion-button>
                </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-item 
          (click)="onClickMenu($event,menuObj)"
          *ngFor="let menuObj of menu"
          lines="none"
          [class]="menuObj.class"
        >
        <!-- <ion-button [routerLink]="[menuObj.link]">Log In </ion-button> -->

        <a [href]="menuObj.link" (contextmenu)="onRightClick($event,menuObj)" (click)="onClickMenu($event,menuObj)" >
          
          <ion-icon
            [name]="menuObj.icon"
            size="small"
            slot="start"
            *ngIf="!menuObj.children"
          ></ion-icon>
         

          <ion-label *ngIf="!menuObj.children">{{ menuObj.title }}</ion-label>

          <ion-item-group
            submenu
            [class.visible]="!menuObj.children"
            *ngIf="menuObj.children">
          
          <ion-item (mouseenter)="onHover(menuObj)">
            <ion-icon  slot="start" [name]="menuObj.icon" size="small"></ion-icon>
            <ion-label>{{ menuObj.title }}</ion-label>
          </ion-item>

            <div class="child" *ngIf="menuObj.isOpen">
              <ion-item
                submenu-item
                *ngFor="let submenuObj of menuObj.children"
                (click)="onClickMenu($event,submenuObj,true)"
                lines="none"
                [class]="submenuObj.class"
              >
              <!-- [class]="isActive(submenuObj)" -->
              <a [href]="submenuObj.link" (contextmenu)="onRightClick($event,submenuObj)" (click)="onClickMenu($event,submenuObj,true)">
                <ion-icon slot="start" [name]="submenuObj.icon" size="small"></ion-icon>
              
                <ion-label>{{ submenuObj.title }}</ion-label>
                </a>
            
              </ion-item>
            </div>
          </ion-item-group>
        </a>
       
        </ion-item>

        <ion-item class="logout" (click)="doLogout()" lines="none"> 
          <ion-icon name="log-out-outline"></ion-icon>       
          <ion-label>Logout</ion-label>
        </ion-item>
      
        <!-- <ion-item        
        (click)="doLogout()"
        lines="none">        
        <ion-icon slot="start" name="log-out-outline" class="menu-icon ng-star-inserted" size="small"> </ion-icon>
        <ion-label  i18n="@@home-logoutBtn" >Logout</ion-label>
      </ion-item> -->
      </ion-list>
    </ion-content>
  
  </ion-menu>


  <ion-content id="main-content" appPageAnimation>
    <ion-header class="site_header">
      <ngx-header (emitToggleSidebar)="onToggleSidebar($event)"></ngx-header>
    </ion-header>
    <ion-progress-bar  *ngIf="isProgress"
    type="indeterminate"></ion-progress-bar>
  <ion-router-outlet ></ion-router-outlet> 
  <!-- <ion-footer fixed class="site_footer">
    <ngx-footer> </ngx-footer>
  </ion-footer> -->
</ion-content>
</ion-split-pane>