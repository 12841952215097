import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { UserStorageAction } from "./user.actions";
import { UserModel } from "./user.model";

export const initialUserState : UserModel = {
  selectedUser : null 
}

export const STATE_USER = new StateToken<UserModel>('saas_user');

@State({
  name : STATE_USER ,
  defaults : initialUserState
})

@Injectable()
export class UserState
{
  constructor()
  {

  }

  @Action(UserStorageAction)
  setUser(ctx : StateContext<UserModel> , action : UserStorageAction)
  {
    ctx.patchState(
      {
        selectedUser : action.user
      }
    )
  }

}