import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
// import { NbSidebarService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { SignInResponse } from 'sass_userbase_package';
import { APP_ROUTES, FILE_NAME, ROLE, ROUTER_PARAM } from "../../../../../app/util/constants";
import { AlertModalStatus } from '../../../../model/alert-modal/alert-modal.model';
import { ErrorAlertVal } from '../../../../model/Response.model';
import { selectLoginResponse } from '../../../../pages/auth/auth.selectors';
import { LoggerService } from '../../../../services/logger/logger.service';
import { BASE_CONFIG } from '../../../../util/base-settings';
import { UtilFunctions } from '../../../../util/util';
@Component({
  selector: 'ngx-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, AfterContentChecked {
  userProfile:SignInResponse = null; 
  @Output() emitToggleSidebar = new EventEmitter();
  title: string='';
  description : string = '';
  isContractorFlow: boolean=false;
  alertMsg: ErrorAlertVal | string;
  modalStatus: AlertModalStatus;
  showAlertModal: boolean = false;
  hideHeader: boolean;
  isShowMenu: boolean = true ;
  constructor(
    private router: Router,
    private logger: LoggerService,
    private store: Store,
    private routerFinder : ActivatedRoute,
    private util: UtilFunctions,
    // private sidebarService:NbSidebarService,
    //private layoutService: LayoutService,
    private cd:ChangeDetectorRef
    ) { }

    ngAfterContentChecked() {
//       try {
//                 if (BASE_CONFIG.IS_DEBUG) console.log("ngAfterContentChecked");

//     //     if(window.parent.document.getElementById('neb-sidebar'))
//     //     {
//     //       var anchrLs = window.parent.document.getElementById('neb-sidebar').getElementsByTagName('a');
//     //       for(var i=0; i<anchrLs.length; ++i) {
//     //        if(anchrLs[i].title) {
//     //          anchrLs[i].title=""
//     //        }
//     //    }

//     // }
// } catch (err) {
//   let logRequest = this.logger.buildRequest(
//     FILE_NAME.HEADER_COMPONENT,
//     "error in ngAfterContentChecked",
//     err.toString(),
//     "ngAfterContentChecked",
//   );
//   this.logger.log(logRequest);
// }

    }

    onRouteChange(pCurrentUrl): void {
      try{
        if (BASE_CONFIG.IS_DEBUG) console.log("onRouteChange");
        this.addClassToPage(false);


        let url=pCurrentUrl.split('?')[0];
       

         switch(url){   
          case '/' + APP_ROUTES.DASHBOARD : {
            this.title = $localize`:@@headerComp-dashboard:Dashboard`;
            break ;
          }      
          case '/' + APP_ROUTES.USER : {
            this.title = $localize`:@@headerComp-users:Users`;
            break ;
          }   
          case '/' + APP_ROUTES.PRICING : {
            this.title = $localize`:@@headerComp-pricing:Pricing`;
            break ;
          }   
          case '/' + APP_ROUTES.PAYMENT_REPORT : {
            this.title = $localize`:@@headerComp-PaymentReport:Payment Report`;
            break ;
          }
          case '/' + APP_ROUTES.SUBSCRIBERS_LIST : {
            this.title = $localize`:@@headerComp-subscribers:Subscribers`;
            break ;
          }
          case '/' + APP_ROUTES.DASHBOARD : {
            this.title = $localize`:@@headerComp-dashboard:Dashboard`;
            break ;
          }
         }
         this.cd.markForCheck();
    } catch (err) {
      let logRequest = this.logger.buildRequest(
    FILE_NAME.HEADER_COMPONENT,
    "error in onRouteChange",
    err.toString(),
   "onRouteChange",
    );
   this.logger.log(logRequest);
    }
    }
    addClassToPage(pIsAdd){
      try{
        if (BASE_CONFIG.IS_DEBUG) console.log("addClassToPage");
        let elNgxHeader = document.querySelector('ngx-header') ;
        let parentEl=elNgxHeader.parentElement.parentElement;
        if(pIsAdd&&parentEl){
          parentEl.classList.add('grey_bg');
        }else{
          parentEl.classList.remove('grey_bg');

        }
      // grey_bg
          } catch (err) {
           let logRequest = this.logger.buildRequest(
            FILE_NAME.HEADER_COMPONENT,
            "error in addClassToPage",
            err.toString(),
            "addClassToPage",
       );
       this.logger.log(logRequest);
       }
    }
  ngOnInit(): void {
    try{
      if (BASE_CONFIG.IS_DEBUG) console.log("ngOnInit");
      this.onRouteChange(this.router.url);
      this.router.events.subscribe(event =>{
      if (event instanceof NavigationEnd){
        this.onRouteChange(event.url);
      this.showAlertModal = false ;
          }
          })
  
    

  } catch (err) {
    let logRequest = this.logger.buildRequest(
  FILE_NAME.HEADER_COMPONENT,
  "error in ngOnInit",
  err.toString(),
 "ngOnInit",
  );
 this.logger.log(logRequest);
  }
  }

  toggleSidebar(){ 
    this.emitToggleSidebar.emit(true)


  }


  navToInvestorAll(): void {
    // this.router.navigate([APP_ROUTES.INVESTORS]);
  }


  onClickBack()
  {
    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log("onClickBack");
      let url = this.router.url.split("?")[0];
      this.util.goBack();
    } catch (error) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HEADER_COMPONENT,
        "error in onClickBack",
        error.toString(),
        "onClickBack"
      );
      this.logger.log(logRequest);
    }
  }

  onAlertModalClose(isConfirm): void
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onAlertModalClose");
      this.alertMsg = null;
      if(this.modalStatus == AlertModalStatus.failed)
      {
        this.showAlertModal = false;
      }
      else if(this.modalStatus == AlertModalStatus.success)
      {
        this.showAlertModal = false;
        this.util.goBack();
      }
      else if(this.modalStatus == AlertModalStatus.confirm)
      {
        isConfirm ? this.util.goBack() : this.showAlertModal = false ;
      }
      this.modalStatus = null ;
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HEADER_COMPONENT,
        "error in onAlertModalClose",
        err.toString(),
        "onAlertModalClose",
      );
      this.logger.log(logRequest);
    }

  }

}
