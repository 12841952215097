
<div class="card custom_modal set_alert_popup">


  <div class="card-body">

    <div class="modal_align">
    <div class="modal_icon">
      <div class="success-img icon_sec" *ngIf="data.status == modalStatus.success">
        <ion-icon name="checkmark-circle"></ion-icon>
      </div>

      <div class="failure-img icon_sec" *ngIf="data.status == modalStatus.failed">
        <ion-icon class="failure-status" name="close-circle"></ion-icon>
      </div>

      <div class="conform-img icon_sec" *ngIf="data.status == modalStatus.confirm">
     
        <ion-icon name="alert-circle"></ion-icon>
        
      </div>

    </div>
    <div class="description-popup">
      <h2
          [ngClass]="{
            'popup-success': data.status == modalStatus.success,
            'popup-fail': data.status == modalStatus.failed,
            'popup-conform': data.status == modalStatus.confirm
          }"
        >
          {{ data.title }}
        </h2>
      
      <p *ngIf="!isInnerHtml">{{ data.desc }}</p>
      <p *ngIf="isInnerHtml" [innerHtml]="data.desc">{{ data.desc }}</p>
    </div>
   </div>
    <div class="modal_footer">
      <ion-button class="btn theme_outline_btn"
        [ngClass]="{
          'popup-button-success': data.status == modalStatus.success,
          'popup-button-failure': data.status == modalStatus.failed,
          'popup-button-conform': data.status == modalStatus.confirm
        }"
        (click)="onClose(false)"
      >
        {{
          data.status == modalStatus.confirm
            ? data.buttonName[0]
            : data.buttonName
        }}
      </ion-button>

      <ion-button
        (click)="onClose(true)"
        *ngIf="data.status == modalStatus.confirm"
        class="btn theme_red_btn ml-20"
      >
        {{ data.buttonName[1] }}
      </ion-button>
    </div>

  </div>
</div>
