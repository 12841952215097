import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "../../shared/shared.module";
import { FormErrorsComponent } from "./form-errors.component";

@NgModule({
  imports : [
    FormsModule,
    CommonModule,
    SharedModule
  ],
  declarations : [
    FormErrorsComponent
  ],
  exports : [
    FormErrorsComponent
  ]
})

export class FormErrorsComponentModule 
{
  
}