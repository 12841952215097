import {
  ChangeDetectorRef, Component,
  OnInit
} from "@angular/core";
import {  Router } from "@angular/router";

import { Capacitor } from '@capacitor/core';

import {  Observable } from 'rxjs';
import { ClearStoreFunction } from "./core/store/clearStore";
import { HttpService } from "./services/http/http.service";
import { LoggerService } from "./services/logger/logger.service";

import { BASE_CONFIG } from './util/base-settings';
import { FILE_NAME, PLATFORM, SESSION_STORAGE_KEYS } from "./util/constants";
import { UtilFunctions } from "./util/util";

import { Device } from '@capacitor/device';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Platform } from "@ionic/angular";
import { selectRememberLogin } from "./pages/auth/auth.selectors";
import { Store } from "@ngxs/store";
import { RememberLoginStorageAction, TokenStorageAction } from "./pages/auth/auth.actions";

@Component({
  selector : "ngx-app",
  templateUrl : "./app.component.html",
})
export class AppComponent implements OnInit {
  showLoading$:Observable<boolean> ;
  isPrgrsCompleted: any;
  isProgress: boolean;

  constructor(

    private logger: LoggerService,
    private httpService: HttpService,
    private util: UtilFunctions,
    private storeClear : ClearStoreFunction,
    private store: Store,

  ) {
    this.initializeApp();
  
  }
configPushNotification(){
  if(BASE_CONFIG.PLATFORM==PLATFORM.ios){
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        console.log('Push registration success, token: ' + token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
      }
    );
    }
}

ngAfterViewInit()
{


  // this.router.events.subscribe(event =>{
  //   if (event instanceof NavigationStart){
  //   this.isProgress = true;
  //   }
  //   if (event instanceof NavigationEnd){
  //     this.isProgress = false;

  //   }

  // })


    this.httpService.dataSubject.subscribe(res => {
      this.isProgress = null
      this.isProgress = res
    })


  // this.cdr.detectChanges();
}

  ngOnInit(): void {


        //  if(this.platform.platforms()[0]=='desktop'){
        //    BASE_CONFIG.PLATFORM=this.platform.platforms()[0];
        //  }
  }
  async initializeApp() {
    try
    {
    if(BASE_CONFIG.IS_DEBUG) console.log('initializeApp',BASE_CONFIG.VERSION)
    this.processLoggedInData();
    this.storeClear.forceClearLocalStorage();
       let platform= Capacitor.getPlatform();
    BASE_CONFIG.BROWSER = this.util.getBrowserName();
    BASE_CONFIG.PLATFORM = platform.toUpperCase();
    if(PLATFORM.web == BASE_CONFIG.PLATFORM){
      document.body.className = 'web';
    }else{
      document.body.className = 'app';
      require("../assets/app.scss")
    }
    // document.body.className = platform;
    var deviceid = await Device.getId();
    var deviceInfo = await Device.getInfo()
    BASE_CONFIG.DEVICE = deviceInfo;
    if(deviceid && deviceid.uuid) {
      BASE_CONFIG.DEVICE_ID = deviceid.uuid;
    }
    this.configPushNotification()

    }
    catch (error)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.APP_COMPONENT,
        "error in initializeApp",
        error.toString(),
        "initializeApp"
      );
      this.logger.log(logRequest);
    }

  }
  processLoggedInData(){
    try
    {
    if(BASE_CONFIG.IS_DEBUG) console.log('processLoggedInData');
    if(!window.sessionStorage.getItem(SESSION_STORAGE_KEYS.NEW_SESSION)){
      window.sessionStorage.setItem(SESSION_STORAGE_KEYS.NEW_SESSION,'1');

      let isOnceEntered=false;
    let sLogin=  this.store.select(selectRememberLogin()).subscribe((isRememberMe ) => {
       if(!isOnceEntered&&!isRememberMe){
        isOnceEntered=true;
        this.store.dispatch(new TokenStorageAction(null));
        this.store.dispatch(new RememberLoginStorageAction(true));
       }
     });
     sLogin.unsubscribe();
    }
    }
    catch (error)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.APP_COMPONENT,
        "error in initializeApp",
        error.toString(),
        "initializeApp"
      );
      this.logger.log(logRequest);
    }
  }
 
  routerChangeMethod(url){
    //alert(url);
    //this.addScript();
  }
  // ngAfterViewChecked() {
  //   this.cdr.detectChanges();
  // }
  /*addScript()
    {
      try {
        const scriptElement2 = document.createElement('script');
        scriptElement2.src = "../assets/js/jquery-3.2.1.slim.min.js";
        scriptElement2.type = 'text/javascript';
        document.head.appendChild(scriptElement2);

        const scriptElement1 = document.createElement('script');
        scriptElement1.src = "../assets/js/jquery.meanmenu.js";
        scriptElement1.type = 'text/javascript';
        alert(scriptElement1.src);
        document.head.appendChild(scriptElement1);



        const scriptElement3 = document.createElement('script');
        scriptElement3.src = "../assets/js/custom.js";
        scriptElement3.type = 'text/javascript';
        document.head.appendChild(scriptElement3);
      } catch (error) {
        alert(error);
      }

    }*/
}
