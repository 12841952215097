import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { CaptchaStorageAction, LoginResponseAction, RememberLoginStorageAction, ResetLoginStoreAction, TokenStorageAction } from "./auth.actions";
import { AuthModel } from "./auth.model";

export const initialTokenState : AuthModel = {
  token : null ,
  captchaText : null ,
  loginResponse : null,
  isRememberLogin:true
}

export const STATE_TOKEN = new StateToken<AuthModel>('saas_token');


@State({
  name: STATE_TOKEN,
  defaults: initialTokenState,
})

@Injectable()
export class AuthState
{
  constructor()
  {

  }
  @Action(ResetLoginStoreAction)
  resetLoginStore(ctx : StateContext<AuthModel>)
  {
    ctx.setState(initialTokenState);
  }
  @Action(TokenStorageAction)
  setToken(ctx : StateContext<AuthModel>, action : TokenStorageAction)
  {
    ctx.patchState(
      {
        token : action.token?action.token:null
      }
    );
  }
  @Action(RememberLoginStorageAction)
  setIsRemeberLogin(ctx : StateContext<AuthModel>, action : RememberLoginStorageAction)
  {
    ctx.patchState(
      {
        isRememberLogin :action.remember
      }
    );
  }
  @Action(LoginResponseAction)
  setLoginResponse(ctx : StateContext<AuthModel> , action : LoginResponseAction)
  {
    ctx.patchState(
      {
        loginResponse : action.loginResponse
      }
    );
  }

  @Action(CaptchaStorageAction)
  setCaptchaText(ctx : StateContext<AuthModel> , action : CaptchaStorageAction)
  {
    ctx.patchState(
      {
        captchaText : action.captchaText?action.captchaText:null
      }
    );
  }

}