import { Injectable } from "@angular/core";
import { differenceInDays, format, subDays, subMonths } from 'date-fns';
import { LOG_TYPE } from "../model/logger/logger.model";
import { LoggerService } from "../services/logger/logger.service";
import { BASE_CONFIG } from "./base-settings";
import { DATE_TIME_FORMAT, FILE_NAME, PLATFORM } from "./constants";
// var moment = require("moment");
/// returns formatted  String from Date Object
@Injectable({
    providedIn : "root"
})
export class DateFunctions {
    constructor( private logger : LoggerService){}

    formatDate(pDate, pFormat) : string
    {
        let returnValue;
        try
        {
            let dateVal;
            if(pDate!=null) {
              if(BASE_CONFIG.PLATFORM==PLATFORM.ios){
              pFormat=pFormat.replace(/-/g, "/")+'';
              }
            if (pDate instanceof Date) {
                dateVal = pDate;
            } else {
              if(BASE_CONFIG.PLATFORM==PLATFORM.ios){
              pDate= pDate.replace(/-/g, "/")+'';
              }

                dateVal = new Date(pDate);
            }
            if (dateVal instanceof Date && pFormat) {
              returnValue = format(dateVal, pFormat);
              if(BASE_CONFIG.PLATFORM==PLATFORM.ios){

              returnValue=returnValue.replace(/\//g, "-")+'';
              }
              //   returnValue = moment(dateVal).format(pFormat);
            }
        }
        else
        {
            returnValue=null;
        }
        } catch (err) {
            let logRequest = this.logger.buildRequest(
                FILE_NAME.DATE_FUNCTIONS,
                "error in formating date",
                err.toString(),
                "formatDate()",
                LOG_TYPE.ERROR
            );
            this.logger.log(logRequest);
        }

        return returnValue;
      }

    getPrevDateValues(pStartDate: string, pEndDate: string)
    {
        let retValue: any = null;
        try
        {
            let diffInDays: number = differenceInDays(new Date(pEndDate), new Date(pStartDate)) + 1;

            let prevStartDate: string = this.formatDate(subDays(new Date(pStartDate), diffInDays), DATE_TIME_FORMAT.API_DATE_FORMAT);
            let prevEndDate: string = this.formatDate(subDays(new Date(pEndDate), diffInDays), DATE_TIME_FORMAT.API_DATE_FORMAT);

            retValue = { startDate: prevStartDate, endDate: prevEndDate };
        }
        catch (error)
        {
            let logRequest = this.logger.buildRequest(
                FILE_NAME.DATE_FUNCTIONS,
                "error in getPrevDateValues",
                error.toString(),
                "getPrevDateValues()",
                LOG_TYPE.ERROR
            );
            this.logger.log(logRequest);
        }
        return retValue;
    }

    getLast30Days()
    {
        let retValue: string = '';
        try
        {
            retValue = this.formatDate(subDays(new Date(), 30), DATE_TIME_FORMAT.API_DATE_FORMAT);
        }
        catch (error)
        {
            let logRequest = this.logger.buildRequest(
                FILE_NAME.DATE_FUNCTIONS,
                "error in getLast30Days",
                error.toString(),
                "getLast30Days()",
                LOG_TYPE.ERROR
            );
            this.logger.log(logRequest);
        }
        return retValue;
    }


    getLastMonthsDates(pMonthCount : number)
    {
        let retValue: string = '';
        try
        {
            retValue = this.formatDate(subMonths(new Date(), pMonthCount), DATE_TIME_FORMAT.API_DATE_FORMAT);
        }
        catch (error)
        {
            let logRequest = this.logger.buildRequest(
                FILE_NAME.DATE_FUNCTIONS,
                "error in getLast3Months",
                error.toString(),
                "getLast3Months",
                LOG_TYPE.ERROR
            );
            this.logger.log(logRequest);
        }
        return retValue;
    }
}
