import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { UtilFunctions } from "../../util/util";
import { FilterStorageAction, ManageColumnStorageAction, PerPageStorageAction, SortStorageAction } from "./data-table.actions";
import { DataTableObjModel } from "./data-table.model";

export const initialDataTableState : DataTableObjModel = {
  selectedManageColumn : null ,
  selectedPerPage : null,
  selectedSort :null,
  selectedFilter :null
}

export const EDIT_DATA_TABLE = new StateToken<DataTableObjModel>("saas_manageColumn");

@State({
  name : EDIT_DATA_TABLE ,
  defaults : initialDataTableState
})

@Injectable()

export class DataTableState
{
  constructor(private util : UtilFunctions)
  {

  }

  @Action(ManageColumnStorageAction)
  setMngColObj(ctx : StateContext<DataTableObjModel>,action : ManageColumnStorageAction)
  {
    ctx.patchState({selectedManageColumn : action.MngColObj});
  }

  @Action(PerPageStorageAction)
  setPerPageObj(ctx : StateContext<DataTableObjModel>,action : PerPageStorageAction)
  {
    ctx.patchState({selectedPerPage : action.PerPageObj});
  }
  @Action(SortStorageAction)
  setSortObj(ctx : StateContext<DataTableObjModel>,action : SortStorageAction)
  {
    const state = ctx.getState();
    let selectedSort =this.util.cloneDeep(state.selectedSort);
    if(!selectedSort){
      selectedSort={};
    }
    selectedSort[action.sortObj.pageName]=action.sortObj.value;
    ctx.patchState({selectedSort : selectedSort});
  }
  @Action(FilterStorageAction)
  setFilter(ctx : StateContext<DataTableObjModel>,action : FilterStorageAction)
  {
    const state = ctx.getState();
    let selectedFilter =this.util.cloneDeep(state.selectedFilter);
    if(!selectedFilter){
      selectedFilter={};
    }
    selectedFilter[action.filterObj.pageName]=action.filterObj.value;
    ctx.patchState({selectedFilter : selectedFilter});
  }
}