import { HttpService } from "../http/http.service";

import { BASE_CONFIG } from "../../util/base-settings";
import { FILE_NAME ,SERVICE_URL} from "../../util/constants";
import { LoggerService } from "../logger/logger.service";
import { Injectable } from "@angular/core";
import { APIResponse } from "../../model/Response.model";
import { Observable } from 'rxjs';
import { GetCountsRes, UserMenu } from "dnl-api";

@Injectable({
  providedIn: 'root'
})
export class ApiGetMenuService {

  constructor(private logger:LoggerService,private httpService:HttpService) { }

  apiGetMenuRes(req: null): Observable<APIResponse<UserMenu[]>> {
    let retValue: Observable<APIResponse<UserMenu[]>>;

    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log("apiGetMenuRes");
        retValue = this.httpService.makePostRequest(SERVICE_URL.GET_MENU, req);
      
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.GET_MENU_SERVICE,
      "error in apiGetMenuRes",
      err.toString(),
       "apiGetMenuRes",
        );
       this.logger.log(logRequest);
    }
    return retValue;
  }

  getMenuCount() : Observable<APIResponse<GetCountsRes>>
  {
    let retValue : Observable<APIResponse<GetCountsRes>> ;
    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log("getMenuCount");
      retValue = this.httpService.makePostRequest(SERVICE_URL.GET_COUNT,null);
    } catch (error) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.GET_MENU_SERVICE,
        "error in getMenuCount",
        error.toString(),
        "getMenuCount"
      );
      this.logger.log(logRequest);
    }
    return retValue ;
  }

}
