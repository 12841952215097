import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HomeService
{
    sideMenu = new BehaviorSubject(null);
    setSidemenu(pIsOpen)
    {
      this.sideMenu.next(pIsOpen);
    }
}