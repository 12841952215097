import { CreatePlanModulesReq, PlanObj } from "dnl-api";

export class PlanStorageAction
{
  static readonly type = '[plan] set plan details';
  constructor(public plan: PlanObj) { }
}

export interface PlanModules extends CreatePlanModulesReq
{
  id: number | null;
  name: string;
  isChecked: boolean;
}