import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { PlanStorageAction } from "./plan.actions";
import { PlanModel } from "./plan.model";

export const initialPlanState: PlanModel = {
  selectedPlan: null
};

export const STATE_PLAN = new StateToken<PlanModel>('saas_plan');

@State({
  name: STATE_PLAN,
  defaults: initialPlanState
})

@Injectable()
export class PlanState
{
  constructor() { }

  @Action(PlanStorageAction)
  setPlan(ctx: StateContext<PlanModel>, action: PlanStorageAction)
  {
    ctx.patchState({
      selectedPlan: action.plan
    });
  }

}