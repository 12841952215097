import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { ActionSheetController, AnimationController, ModalController, NavController, ToastController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import * as cloneDeep from 'lodash.clonedeep';
// import { NbComponentStatus, NbDialogService, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrConfig, NbToastrService } from '@nebular/theme';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { CustomDialogComponent } from "../components/custom-dialog/custom-dialog.component";
import { DataTableService } from "../components/data-table/data-table.service";
import { AlertModalData, AlertModalStatus } from "../model/alert-modal/alert-modal.model";
import { LOG_TYPE } from '../model/logger/logger.model';
import { PopoverActionCode } from "../model/project/project.model";
import { ErrorAlertVal, Status } from '../model/Response.model';
import { selectLoginResponse } from '../pages/auth/auth.selectors';
import { LoggerService } from "../services/logger/logger.service";
import { TokenRenewalService } from "../services/renew-token/token-renewal.service";
import { BASE_CONFIG } from './base-settings';
import { APP_ROUTES, CurrTab, FILE_NAME, ID, PLATFORM, ROUTER_PARAM, ToastTypes } from './constants';
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root",
  })

export class UtilFunctions {

    // config: NbToastrConfig;
    index = 1;
    destroyByClick = true;
    duration = 2000;
    hasIcon = true;
    // position: NbGlobalPosition = NbGlobalPhysicalPosition.BOTTOM_RIGHT;
    preventDuplicates = false;
    modalCount=0;
  isJwtModalStarted: boolean;
    constructor(
        private dialog: ModalController,
        private router:Router,
        private route:ActivatedRoute,
        private logger:LoggerService,
        // private toastrService: NbToastrService,
        private toastController: ToastController,
        private store: Store,
        private actionSheetController: ActionSheetController,
        private navController :NavController,
        private tokenRenewal: TokenRenewalService,
        private dataTableService: DataTableService,
        public animationCtrl: AnimationController,
        private file : File,
        private fileOpener: FileOpener,
        private http: HttpClient

        ) {
        }
getErrorAlertValue(pError, pIsNeedCode,isAlertExist):ErrorAlertVal {
    let errorAlert:ErrorAlertVal ={
        codeList:[],
        valueList:[]
    };
    // Vue.toasted.show('hi');

    try {
        if (pError && pError.error && pError.error.length) {

        if (!(true)) {//pIsNeedCode
                for (let index = 0; index < pError.error.length; index++) {
                    if (pError.error[index].value) {
                        errorAlert.valueList.push(pError.error[index].value);
                    }
                }
            }

        else if (true) {//pIsNeedCode
                for (let index = 0; index < pError.error.length; index++) {
                    if (pError.error[index].code) {
                        errorAlert.codeList.push( pError.error[index].code);
                    }
                    if (pError.error[index].value ) {
                        errorAlert.valueList.push(pError.error[index].value);
                    }
                }
            }
            let logRequest = this.logger.buildRequest(
              FILE_NAME.UTIL,
            "error in getErrorAlertValue",
           JSON.stringify(pError),
             "getErrorAlertValue",
              );
              this.logger.log(logRequest);
    }

        else {
            errorAlert.valueList = ["Failed"];
        }

    } catch (err) {
        let logRequest = this.logger.buildRequest(
            FILE_NAME.C_DROPDOWN_COMPONENT,
          "error in getErrorAlertValue",
          err.toString(),
           "getErrorAlertValue",
            );
            this.logger.log(logRequest);
    }

    //  return errorAlert;
   return errorAlert;
}

  handleJWT(pError,pDonotNav?):boolean {
    let retValue:boolean = false;

    try {

        if (pError) {
            let queryParams = {} as any;
            let isJwt = false;
            if (pError.error) {
                for (let index = 0; index < pError.error.length; index++) {
                    const err = pError.error[index];
                    if (err.code == Status.ERROR_JWT_01 ||
                        err.code == Status.ERROR_JWT_02 ||
                        err.code == Status.ERROR_AUT2 ||
                        err.code == Status.ERROR_PER) {
                        queryParams[ROUTER_PARAM.PARAM] = [err.code, err.value];
                        isJwt = true;

                    }
                }
            }

            if (isJwt) {

                retValue = true;
                if(!pDonotNav){
                if (queryParams.param.length > 0)
                {
                 let sLogin=   this.store.select(selectLoginResponse()).subscribe(async (loginResponse) =>
                    {
                        if (loginResponse&&loginResponse.authorization)
                        {
                          // let error =   this.getErrorAlertValue(pError, true, false)
                          let url=this.router.url;

                          if(url=='/'+APP_ROUTES.DASHBOARD)
                          {
                            this.router.navigate([APP_ROUTES.LOGIN],{queryParams : {code:queryParams.param[0],error :queryParams.param[1]},replaceUrl:true,state: { allow: true }});
                          }
                          else
                          {
                            await  this.navToLogin(queryParams)
                          }
                      }
                  });

                  sLogin.unsubscribe();
            } else {

                retValue = false;
            }
          }
        }
        }

    } catch (err) {
        let logRequest = this.logger.buildRequest(
            FILE_NAME.UTIL,
          "error in getUserRoles",
          err.toString(),
           "getUserRoles",
            );
            this.logger.log(logRequest);
    }
    return retValue;
}


  async navToLogin(queryParams?)
  // :Promise<boolean>
  {
    let retValue:boolean = false;

    try {
      this.modalCount=0;
     let modal= await this.dialog.getTop();
     let id=modal?modal.id:null;
     if(id!=ID.JWT_MODAL&&!this.isJwtModalStarted){
      this.isJwtModalStarted=true;



        let modalData: AlertModalData = {
            buttonName: "OK",
            desc: queryParams && queryParams.param[1]?queryParams.param[1]:"Failed",
            status: AlertModalStatus.failed,
            title: "Session Expired",
          };

         
          var dialogRef = await this.dialog.create( {
            component: CustomDialogComponent,
            id:ID.JWT_MODAL,
            cssClass:'auto-height',
            componentProps:{
              data:modalData,
              isJwtModal:true,
              emitFunc: await this.getConfirm.bind(this,queryParams.param)
            },
              backdropDismiss: false,

          },);
          await dialogRef.present();
          this.isJwtModalStarted=false;
        
     }

    } catch (err) {
        let logRequest = this.logger.buildRequest(
            FILE_NAME.UTIL,
          "error in navToLogin",
          err.toString(),
           "navToLogin",
            );
            this.logger.log(logRequest);
    }
}

  async getConfirm(pQueryParams){
    try {
      if(BASE_CONFIG.IS_DEBUG) console.log('getConfirm');
    let modal= await this.dialog.getTop();

    let id=modal?modal.id:null;
    // &&!modal.isOpen
   if(id&&this.modalCount<3){

    await this.dialog.dismiss().then(async ()=>{
      this.modalCount++;
      await this.getConfirm(pQueryParams);


    });
   }
    this.router.navigate([APP_ROUTES.LOGIN],{queryParams : {code:pQueryParams[0],error :pQueryParams[1]},replaceUrl:true,state: { allow: true }});
  } catch (err) {
    let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
      "error in getConfirm",
      err.toString(),
       "getConfirm",
        );
        this.logger.log(logRequest);
}
  }
updateCurrRouteQuery(pQuery): void {
    try {

        if(this.router.url){
            this.router.navigate(
                [],
                {
                  relativeTo: this.route,
                  queryParams: { [ROUTER_PARAM.PARAM]: pQuery },
                  queryParamsHandling: 'merge'
                });
            // this.router.navigate([this.router.url], {replaceUrl:true});

            }else{
                throw 'invalid current route'
            }


    } catch (err) {
        let logRequest = this.logger.buildRequest(
            FILE_NAME.UTIL,
          "error in updateCurrRouteQuery",
          err.toString(),
           "updateCurrRouteQuery",
            );
            this.logger.log(logRequest);
    }
}

cloneDeep(pValue) {
    var cloneDeepObj = cloneDeep;
    let retValue;

    try {
        retValue = cloneDeepObj(pValue)
    } catch (err) {
        let logRequest = this.logger.buildRequest(
            FILE_NAME.UTIL,
          "error in cloneDeep",
          err.toString(),
           "cloneDeep",
            );
            this.logger.log(logRequest);
    }
    return retValue;
}



//   toShowToast( data: string,  type: NbComponentStatus,) {
//     try{
//         if(BASE_CONFIG.IS_DEBUG) console.log("toShowToast");
//     const config = {
//       status: type,
//       destroyByClick: this.destroyByClick,
//       duration: this.duration,
//       hasIcon: this.hasIcon,
//       position: this.position,
//       preventDuplicates: this.preventDuplicates,
//     };


//     this.index += 1;
//     this.toastrService.show(

//       data,
//       null,
//       config);
//   }
//   catch(err)
//   {
//     let logRequest = this.logger.buildRequest(
//       FILE_NAME.UTIL,
//     "error in toShowToast",
//     err.toString(),
//      "toShowToast",
//       );
//      this.logger.log(logRequest);
//   }
// }

closeFilterPopoveronClick( event,ref: string, componentRef) {
    try{
        if(BASE_CONFIG.IS_DEBUG) console.log("closeFilterPopoveronClick");
        if(event.srcElement.id!=ref) {
            if(componentRef) {
                componentRef.closeFilterPopOver();
            }
            }
  }
  catch(err)
  {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.UTIL,
    "error in closeFilterPopoveronClick",
    err.toString(),
     "closeFilterPopoveronClick",
      );
     this.logger.log(logRequest);
  }
}
 copyTextToClipboard(pText,pMgs){
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("copyTextToClipboard");

    var tempInput = document.createElement("input");
    tempInput.value = pText;
    document.body.appendChild(tempInput);
    tempInput.select();
    //  tempInput.setSelectionRange(0, 99999); /* For mobile devices */

    //Copy the text inside the text field
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    // this.toShowToast(pMgs,'success');
    } catch (err) {
  let logRequest = this.logger.buildRequest(
    FILE_NAME.UTIL,
    "error in copyTextToClipboard",
    err.toString(),
    "copyTextToClipboard",
  );
  this.logger.log(logRequest);
   }
 }

handleErrorStatus(fileName : string , functionName : string , errorMessage : string)
{
  try {
    let modalData: AlertModalData = {
      buttonName: "OK",
      desc: "Something went wrong !",
      status: AlertModalStatus.failed,
      title: null,
    };

    const dialogRef =this.dialog.create( {
      component:CustomDialogComponent,
      componentProps:{
        data:modalData,

      },
        // closeOnBackdropClick: false,


    },);
    // dialogRef.onClose.subscribe((isConfirm) => {

    //   if(!isConfirm)
    //   {
    //     this.logOutService.apiLogoutRes().subscribe({
    //       next : val => {
    //         this.storeClear.toClearStore();
    //         this.router.navigate([APP_ROUTES.LOGIN]);
    //       },
    //       error : (error : HttpErrorResponse) => {
    //         this.storeClear.toClearStore();
    //         this.router.navigate([APP_ROUTES.LOGIN]);
    //         let logRequest = this.logger.buildRequest(
    //           FILE_NAME.UTIL,
    //           "error in doLogout",
    //           error.message.toString(),
    //           "doLogout"
    //         )
    //         this.logger.log(logRequest);
    //       }
    //     })
    //   }
    //   else{
    //   // this.onDialogClose.emit();
    //   }
    // });
    let logRequest = this.logger.buildRequest(
      fileName,
      "error in " + functionName,
      errorMessage,
      functionName
    )
    this.logger.log(logRequest);

  } catch (error) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.UTIL,
      "error in handleErrorStatus",
      error.toString(),
      "handleErrorStatus"
    );
    this.logger.log(logRequest);
  }
}


toHideColumnsForMbl(pTableSettings,pColumns) {
  let retValue = null;
  try {
    retValue = this.cloneDeep(pTableSettings);
    for (let i = 0; i < pColumns.length; i++) {
     delete retValue.columns[pColumns[i]]
    }
  } catch (err : any) {
      let logRequest = this.logger.buildRequest(
          FILE_NAME.UTIL,
          "error in toHideColumnsForMbl",
          err.toString(),
          "toHideColumnsForMbl",
        );
        this.logger.log(logRequest);
  }
  return retValue;
}

tablePopoverOptForMbl(retValue) {

  try {
      retValue = retValue.filter(({ id }) => id !== PopoverActionCode.DELETE && id !== PopoverActionCode.RESTORE);
    
  } catch (err : any) {
      let logRequest = this.logger.buildRequest(
          FILE_NAME.UTIL,
          "error in tablePopoverOptForMbl",
          err.toString(),
          "tablePopoverOptForMbl",
        );
        this.logger.log(logRequest);
  }
  return retValue;
}

async onGetActionSheet(pButton){
  try{
    if (BASE_CONFIG.IS_DEBUG) console.log("onGetActionSheet");
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'my-custom-class',
      buttons: pButton
    });
    await actionSheet.present();
  }catch (err : any) {
    const body = this.logger.buildRequest(
      FILE_NAME.UTIL,
      "error in onGetActionSheet",
      err.toString(),
      "onGetActionSheet",
      LOG_TYPE.ERROR
    );
    this.logger.log(body);
  }
}
 async navigate(pUrl , pQueryParams? , state?,replaceUrl? ){
  try{
    if (BASE_CONFIG.IS_DEBUG) console.log("navigate");
        await this.navController.navigateRoot([pUrl],{queryParams : pQueryParams,state : state,replaceUrl:replaceUrl})


  }catch (err : any) {
    const body = this.logger.buildRequest(
      FILE_NAME.UTIL,
      "error in navigate",
      err.toString(),
      "navigate",
      LOG_TYPE.ERROR
    );
    this.logger.log(body);
  }
}
  async goBack(pIsDefaultNav?,pState?){
  try{
    if (BASE_CONFIG.IS_DEBUG) console.log("goBack");
    // let currentUrl=this.router.url;

    let url = this.router.url.split("?")[0];

  }catch (err : any) {
    const body = this.logger.buildRequest(
      FILE_NAME.UTIL,
      "error in goBack",
      err.toString(),
      "goBack",
      LOG_TYPE.ERROR
    );
    this.logger.log(body);
  }
}
 async renew(){
  try{
    if (BASE_CONFIG.IS_DEBUG) console.log("renew");
 let retData= await this.tokenRenewal.renewToken().toPromise().then();
if(retData&&retData.error){
  
  this.handleJWT(retData)
}

  }catch (err : any) {
    const body = this.logger.buildRequest(
      FILE_NAME.UTIL,
      "error in renew",
      err.toString(),
      "renew",
      LOG_TYPE.ERROR
    );
    this.logger.log(body);
  }
}
//  canAllowContractModule(pUrl):boolean{
//   try{
//       if(pUrl==APP_ROUTES.CONTRACTOR_APPOINTMENTS||pUrl==APP_ROUTES.CONTRACTOR){
//           return true;
//       }else{
//         return false;
//       }
//   }catch (err : any) {
//     const body = this.logger.buildRequest(
//       FILE_NAME.UTIL,
//       "error in canAllowPage",
//       err.toString(),
//       "canAllowPage",
//       LOG_TYPE.ERROR
//     );
//     this.logger.log(body);
//   }
// }

  /**
   * @param  {string} pMsg Toast Message
   * @param {string} pToastType Toast Type (Success, Failure, Warning)
   * @param {boolean} isNeedBtn Need Button (Default False)
   * @param {string} pButtonTxt Toast Button Text (Optional)
   * @param {number} pDuration Toast Duration (Optional)
   */
   async toShowToast(pMsg: string, pToastType: string, pButtonTxt?: string, pDuration?: number, pPath?: string, isNeedBtn: boolean = false,pIsNavFirst?)
   {
     try
     {
       if (BASE_CONFIG.IS_DEBUG) console.log("toShowToast");

       if(pIsNavFirst){
        // await this.navigate([pPath],{ state: { allow: true }})
      await this.navigate('/'+pPath)
       }

       let button: any[] = [];
       if (isNeedBtn)
       {
         button = [
           {
             text: pButtonTxt ? pButtonTxt : $localize`:@@util.toastBtnOK:OK`,
             handler: () =>
             {
             }
           }
         ];
       }
       let toast = await this.toastController.create({
         message: pMsg,
         duration: pDuration ? pDuration : 3000,
         // icon: 'information-circle',
         cssClass: 'toast-custom-class',
         position: 'bottom',
         color: pToastType,
         buttons: button
       });

       if (pPath)
       {

         toast.onDidDismiss().then(()=>{
           if(!pIsNavFirst){
          this.router.navigate([pPath],{ state: { allow: true }})
           }
        })
       }

       await toast.present();
     }
     catch (err)
     {
       const body = this.logger.buildRequest(
         FILE_NAME.UTIL,
         "error in toShowToast",
         err.toString(),
         "toShowToast",
         LOG_TYPE.ERROR
       );
       this.logger.log(body);
     }
   }
   async toDataURL(url) {
    return await fetch(url).then(async (response) => {
            return await response.blob();
        }).then(blob => {
            return URL.createObjectURL(blob);
        });
}

   async downLoadFile(data: any,  name: string, pIsNewTab?: boolean) {
    try{
      if(BASE_CONFIG.IS_DEBUG) console.log("downLoadFile");
      // let data =
    let blob = new Blob([data], { type: data.type});

    if(data.type == "application/json") {
      let res = JSON.parse(await blob.text());
      if(res && res.status == Status.Error){
        let isJwt = this.handleJWT(res);
         if (!isJwt)
          {
            let errorMsg = this.getErrorAlertValue(res, true, false)
            if(errorMsg && errorMsg.valueList && errorMsg.valueList.length>0) {
            this.toShowToast(errorMsg.valueList.toString(), ToastTypes.Failure)
            }
          }
      }
    }else{
     var downloadURL = data.type?window.URL.createObjectURL(blob):await this.toDataURL(data);

        var link = document.createElement("a");
        link.href = downloadURL;
        link.download = name;
        link.click();
        if (pIsNewTab) {
          let win = window.open(downloadURL);
        }
        window.URL.revokeObjectURL(downloadURL);
    
  }

  }
  catch(err)
  {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.UTIL,
    "error in downLoadFile",
    err.toString(),
     "downLoadFile",
      );
     this.logger.log(logRequest);
  }
}

toPrintFile(data: any,  name: string) {
  try{
    if(BASE_CONFIG.IS_DEBUG) console.log("toPrintFile");

  let blob = new Blob([data], { type: data.type});
  // var blob = this.b64toBlob(data, 'application/zip', data.byteLength);
  var downloadURL = window.URL.createObjectURL(blob);

  window.open(downloadURL);

  }
catch(err)
{
  let logRequest = this.logger.buildRequest(
    FILE_NAME.UTIL,
  "error in toPrintFile",
  err.toString(),
   "toPrintFile",
    );
   this.logger.log(logRequest);
}
}

  getBrowserName(): string
  {
    let browserName: string = '';
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getBrowserName");

      let userAgent: string = navigator.userAgent.toLowerCase();

      if (userAgent.indexOf("chrome") > -1)
      {
        browserName = "Chrome";
      }
      else if (userAgent.indexOf("safari") > -1)
      {
        browserName = "Safari";
      }
      else if (userAgent.indexOf("firefox") > -1)
      {
        browserName = "Mozilla Firefox";
      }
      else if (userAgent.indexOf("opr") > -1 || userAgent.indexOf("opera") > -1)
      {
        browserName = "Opera";
      }
      else if (userAgent.indexOf("edge") > -1)
      {
        browserName = "MS Edge (Legacy)";
      }
      else if (userAgent.indexOf("edg") > -1)
      {
        browserName = "MS Edge (Chromium)";
      }
      else if (userAgent.indexOf("trident") > -1)
      {
        browserName = "MS Internet Explorer";
      }
      else
      {
        browserName = "Other";
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in getBrowserName",
        err.toString(),
        "getBrowserName",
      );
      this.logger.log(logRequest);
    }
    return browserName;
  }
   enterAnimation (baseEl: any) {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("enterAnimation");
    const root = baseEl.shadowRoot;

    const backdropAnimation = this.animationCtrl.create()
      .addElement(root.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = this.animationCtrl.create()
      .addElement(root.querySelector('.modal-wrapper')!)
      .fromTo('transform', 'translateX(50px)', 'translateX(0px)')
      .fromTo('opacity', '1', '1')
      // .keyframes([
      //   { offset: 0, opacity: '0', transform: 'scale(0)' },
      //   { offset: 1, opacity: '0.99', transform: 'scale(1)' }
      // ]);

    return this.animationCtrl.create()
      .addElement(baseEl)
      .easing('ease-in')
      .duration(250)
      .addAnimation([backdropAnimation, wrapperAnimation]
        );
      }
      catch (err)
      {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.UTIL,
          "error in enterAnimation",
          err.toString(),
          "enterAnimation",
        );
        this.logger.log(logRequest);
      }
  }

   leaveAnimation  (baseEl: any)  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("leaveAnimation");
    return this.enterAnimation(baseEl).direction('reverse');
  }
  catch (err)
  {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.UTIL,
      "error in leaveAnimation",
      err.toString(),
      "leaveAnimation",
    );
    this.logger.log(logRequest);
  }
  }

  appendPagerFooter() {
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("appendPagerFooter");
// if(!this.toCheckPlatformisWeb()){
  let dnlFooter= document.getElementById('dnl-footer');

      let dnlFooterPag= document.getElementById('dnl-footer-pag');
      let dnlFooterPagLength= document.getElementById('dnl-footer-pag-length')
      let dnlFooterRowOption= document.getElementById('dnl-footer-row-option')

      let paginationBottom= document.getElementsByClassName('pagination-bottom')[0]
      let dnlPaginationLenoption= document.getElementsByClassName('dnl-pagination-lenoption')[0];
      let dnlRowOption= document.getElementsByClassName('dnl-row-option')[0];

      
      if(dnlFooter){
        if(dnlFooterPag&&paginationBottom){
        if(dnlFooterPag.getElementsByClassName('pagination-bottom')[0]){
          // for(let i=0;i<dnlFooterPag.childNodes.length;i++){
            // if(dnlFooterPag.childNodes[i].nodeName!='ION_FAB'){
              dnlFooterPag.removeChild(dnlFooterPag.childNodes.item(dnlFooterPag.childNodes.length-1))

            // }
          // }
        

        }
    
          dnlFooterPag.appendChild(paginationBottom);
        }

        if(dnlPaginationLenoption&&dnlFooterPagLength){
        if(dnlFooterPagLength.getElementsByClassName('dnl-pagination-lenoption')[0]){
       
          dnlFooterPagLength.removeChild(dnlFooterPagLength.childNodes.item(dnlFooterPagLength.childNodes.length-1))

        }
       
      
          dnlFooterPagLength.appendChild(dnlPaginationLenoption);
        }

        if(dnlRowOption&&dnlFooterRowOption){
        if(dnlFooterRowOption.getElementsByClassName('dnl-row-option')[0]){
       
          dnlFooterRowOption.removeChild(dnlFooterRowOption.childNodes.item(dnlFooterRowOption.childNodes.length-1))

        }
        
          dnlFooterRowOption.appendChild(dnlRowOption);
        }

      }
    // }
    } catch (err : any) {
        let logRequest = this.logger.buildRequest(
            FILE_NAME.UTIL,
            "error in appendPagerFooter",
            err.toString(),
            "appendPagerFooter",
          );
          this.logger.log(logRequest);
    }
  }
  plural(count,objLocalize) {
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("plural");
      let localize='';

      switch(count){
        case 0:
          localize= objLocalize[0];
          break;
        case 1:
          localize= objLocalize[1];
          break;
        default:
          localize= objLocalize['other']
      }
      return localize;

    } catch (err : any) {
        let logRequest = this.logger.buildRequest(
            FILE_NAME.UTIL,
            "error in plural",
            err.toString(),
            "plural",
          );
          this.logger.log(logRequest);
    }
  }
  getFileReader() {
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("getFileReader");
   if(BASE_CONFIG.PLATFORM==PLATFORM.ios){
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
    }else{
    var reader = new FileReader();
     return reader;
    }
    } catch (err : any) {
        let logRequest = this.logger.buildRequest(
            FILE_NAME.UTIL,
            "error in getFileReader",
            err.toString(),
            "getFileReader",
          );
          this.logger.log(logRequest);
    }
  }

  async convertAssetsToBase64(path) : Promise<any> {
    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log('convertAssetsToBase64');
      
      let s = await this.http.get(path, { responseType: 'blob' }).toPromise()
      return s ;
    } catch (error) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in convertAssetsToBase64",
        error.toString(),
        'convertAssetsToBase64'
      );
      this.logger.log(logRequest);
    }
  }
}
